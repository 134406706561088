import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prescrption-deletion',
  templateUrl: './prescrption-deletion.component.html',
  styleUrls: ['./prescrption-deletion.component.css']
})
export class PrescrptionDeletionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
