import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ApiCatalogService } from 'src/app/services/apiCatalog.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { PrescriptionDenialComponent } from '../prescription-denial/prescription-denial.component';
import { ApiPatientReorder } from 'src/app/services/apiPatientReorder.service';
import { UploadPrescriptionComponent } from '../upload-prescription/upload-prescription.component';
import { ToastrService } from 'ngx-toastr';
import { PrescrptionDeletionComponent } from '../prescrption-deletion/prescrption-deletion.component';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';

// import { NoteViewComponent } from '../note-view/note-view.component';
// import { SearchLensComponent } from '../search-lens/search-lens.component';

@Component({
  selector: 'app-webstore-verification',
  templateUrl: './webstore-verification.component.html',
  styleUrls: ['./webstore-verification.component.css']
})
export class WebstoreVerificationComponent implements OnInit {
  onClose: Subject<any> = new Subject();
  item: any;
  patientDetails: any;
  brandColor: string = '';
  modalRef?: BsModalRef;
  eyeDataPatient: any = {
    left: {
    },
    right: {
    },
    leftPrescriptionMultiField: [],
    rightPrescriptionMultiField: [],
  };
  eyeDataOffice: any = {
    left: {},
    right: {},
    rightPrescriptionMultiField: [],
    leftPrescriptionMultiField: [],
  };
  loading: any = {
    left: {
      baseCurveName: false,
      diameter: false,
      sphere: false,
      cylinder: false,
      axis: false,
      add: false,
      color: false,
      lensCount: false,
    },
    right: {
      baseCurveName: false,
      diameter: false,
      sphere: false,
      cylinder: false,
      axis: false,
      add: false,
      color: false,
      lensCount: false,
    }
  }
  eyeType: any;

  prescription: any = {};
  errorMsg: any = {};
  pricingDataOffice: any = {};
  pricingTotalOffice: any = {};
  pricingDataPatient: any = {};
  Soft_CL_Insurance_Carrier_Patient: any
  insuranceApplied_Patient: any
  Soft_CL_Insurance_Carrier_Office: any
  insuranceApplied_Office: any
  insuranceType: any = 'In Network';
  outOfNetworkType: any = '';
  formularyLens: any = '';
  officeInsuranceAllowance: any
  officeInsuranceCopay: any
  officeInsurranceDiscount: any
  insurranceCarrierList: any = []
  confirmPatientInfo: boolean = false
  confirmPrescriptionInfo: boolean = false
  confirmFinancialInfo: boolean = false
  confirmPatientInfoStepActive: boolean = false
  confirmPrescriptionInfoStepActive: boolean = false
  confirmFinancialInfoStepActive: boolean = false
  chargeCommunicationMsg: any
  refundCommunicationMsg: any
  chargeAdditionalMsg: any
  chargeInternalNotes: any
  Soft_CL_Sales_Amount_Office: any
  netDifference: any
  precriptionName: any
  prescriptionUrl: any
  adjustmentPayment: any
  refundInternalNotes: any
  lockLeftEye: boolean = false
  lockRightEye: boolean = false
  ePrescriptionName: any
  prescriptionName: any
  insurranceFieldType: any = ''
  cancelNotes: ""
  cancellationPatientFacingNote: ""
  patientDetailBackup: any
  eStatusByOffice: any;
  stripeProcessingFee: any;
  inssuranceCarrierImage: any;
  isleftPrescriptionChanged: boolean = false;
  isrightPrescriptionChanged: boolean = false;
  couponDetails:any
  storeDetails:any;
  dynamicText: string = '';
  constructor(
    private api: ApiService,
    private apiCatalog: ApiCatalogService,
    private apiPatientReorder: ApiPatientReorder,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router,
    private toastr: ToastrService,
    private apiNotification:NotificationService
  ) { }


  ngOnInit(): void {
    console.log("item???", this.item);
    console.log("user100000000000", this.api.user)
    this.patientDetails = this.item.patientId
    this.patientDetailBackup = JSON.parse(JSON.stringify(this.item.patientId));
    this.stripeProcessingFee = JSON.parse(JSON.stringify(this.item.untrackedPayments[0].FEE || 0));
    this.prescription = {
      Soft_CL_Associated_Rx_Start_Date: this.item.Soft_CL_Associated_Rx_Start_Date,
      Soft_CL_Associated_Rx_Expiration_Date: this.item.Soft_CL_Associated_Rx_Expiration_Date,
      Soft_CL_Associated_Rx_Doctor: this.item.Soft_CL_Associated_Rx_Doctor,
      Soft_CL_Associated_Rx_Office_Name: this.item.Soft_CL_Associated_Rx_Office_Name,
    }
    this.ePrescriptionName = this.item.patientId.ePrescriptionName
    this.prescriptionUrl = this.item.patientId.ePrescription
    this.Soft_CL_Insurance_Carrier_Patient = this.item.Soft_CL_Insurance_Carrier
    this.insuranceApplied_Patient = this.item.insuranceApplied
    this.Soft_CL_Insurance_Carrier_Office = this.item.Soft_CL_Insurance_Carrier
    this.insuranceApplied_Office = this.item.insuranceApplied
    // this.pricingDataOffice = JSON.parse(JSON.stringify(this.pricingDataPatient));
    this.eStatusByOffice = JSON.parse(JSON.stringify(this.item.patientId.eStatus))
    this.parsePricingData()
    this.precriptionName = this.item.patientId.ePrescriptionName
    this.prescriptionUrl = this.item.patientId.ePrescription
    this.checkMultiLensFields()
    this.getInsurranceCarrier();
    this.backupEyeData();
    this.getChargeMessage();
    this.getRefundMessage();
    this.managePatientStatus()
  }

  managePatientStatus() {
    console.log("you thereee")
    if (this.item.webstoreTicketStatus == 'Patient Confirmed by Office') {
      this.confirmPatientInfo = true;
      this.confirmPrescriptionInfo = false;
      this.confirmFinancialInfo = true;
      this.confirmPatientInfoStepActive = true;
      this.confirmPrescriptionInfoStepActive = false;
      this.confirmFinancialInfoStepActive = false;
    }
    if (this.item.webstoreTicketStatus == 'Completed') {

      this.confirmPatientInfo = true;
      this.confirmPrescriptionInfo = true;
      this.confirmFinancialInfo = false;
      this.confirmPatientInfoStepActive = true;
      this.confirmPrescriptionInfoStepActive = true;
      this.confirmFinancialInfoStepActive = true;
    }
    if(this.patientDetails?.eStatus=='existing' && this.item.webstoreTicketStatus !=='Completed'){
      this.confirmPatientInfo = true;
      this.confirmPrescriptionInfo = true;
      this.confirmFinancialInfo = false;
      this.confirmPatientInfoStepActive = true;
      this.confirmPrescriptionInfoStepActive = true;
      this.confirmFinancialInfoStepActive = false;
    }
    
    else if (this.item.webstoreTicketStatus == 'Pending') {
      this.confirmPatientInfo = false;
      this.confirmPrescriptionInfo = true;
      this.confirmFinancialInfo = true;
      this.confirmPatientInfoStepActive = false;
      this.confirmPrescriptionInfoStepActive = false;
      this.confirmFinancialInfoStepActive = false;
    }
    else if (this.item.webstoreTicketStatus == 'Denied') {
      this.confirmPatientInfo = true;
      this.confirmPrescriptionInfo = true;
      this.confirmFinancialInfo = false;
      this.confirmPatientInfoStepActive = true;
      this.confirmPrescriptionInfoStepActive = true;
      this.confirmFinancialInfoStepActive = true;
    }
    else if (this.item.webstoreTicketStatus == "Hold Financial Data") {
      this.getFinancialData()
      this.confirmPatientInfo = true;
      this.confirmPrescriptionInfo = true;
      this.confirmFinancialInfo = false;
      this.confirmPatientInfoStepActive = true;
      this.confirmPrescriptionInfoStepActive = true;
      this.confirmFinancialInfoStepActive = false;


    }
    else if (this.item.webstoreTicketStatus == 'Hold Prescription') {
      this.confirmPatientInfo = true;
      this.confirmPrescriptionInfo = false;
      this.confirmFinancialInfo = true;
      this.confirmPatientInfoStepActive = true;
      this.confirmPrescriptionInfoStepActive = false;
      this.confirmFinancialInfoStepActive = false;
    }
    else if (this.item.webstoreTicketStatus == 'Cancelled') {
      this.confirmPatientInfo = true;
      this.confirmPrescriptionInfo = true;
      this.confirmFinancialInfo = false;
      this.confirmPatientInfoStepActive = true;
      this.confirmPrescriptionInfoStepActive = true;
      this.confirmFinancialInfoStepActive = true;
    }
    this.getFinancialData()

  }


  parsePricingData() {
    this.pricingDataPatient.leftLensCalculations = this.item.leftLensCalculations;
    this.pricingDataOffice.leftLensCalculations = this.item.leftLensCalculations;
    this.pricingDataPatient.rightLensCalculations = this.item.rightLensCalculations;
    this.pricingDataOffice.rightLensCalculations = this.item.rightLensCalculations;
    this.Soft_CL_Sales_Amount_Office = this.item.pricing.totalDueToday
    if (!this.pricingDataPatient.leftLensCalculations) {
      this.pricingDataPatient.leftLensCalculations = {
        price: "0.00",
        grossPrice: 0,
        quantity: "0",
        afterCouponDiscountPrice: 0,
        afterDiscountAndRebatePrice: 0,
        afterRebatePrice: 0,
        couponDiscount: 0,
        couponDiscountPercentage: "0.00",
        discount: 0,
        insuranceAllowance: 0,
        insuranceCopay: 0,
        overageCoInsurance: 0,
        leftEyeDiscountAnnualSavings: 0,
        leftEyeDiscountAnnualSavingsPercentage: 0,
        processingFee: "0.00",
        rebate: "0",
        rebatePercentage: "0.00",
        shippingFee: 0,
        tax: 0,
        totalDueToday: 0,
        totalDueTodayAfterRebate: "0.00"
      };
      this.pricingDataOffice.leftLensCalculations = JSON.parse(JSON.stringify(this.pricingDataPatient.leftLensCalculations));
    }

    if (!this.pricingDataPatient.rightLensCalculations) {
      this.pricingDataPatient.rightLensCalculations = {
        price: "0.00",
        grossPrice: 0,
        quantity: "0",
        afterCouponDiscountPrice: 0,
        afterDiscountAndRebatePrice: 0,
        afterRebatePrice: 0,
        couponDiscount: 0,
        couponDiscountPercentage: "0.00",
        discount: 0,
        insuranceAllowance: 0,
        insuranceCopay: 0,
        overageCoInsurance: 0,
        rightEyeDiscountAnnualSavings: 0,
        rightEyeDiscountAnnualSavingsPercentage: 0,
        processingFee: "0.00",
        rebate: "0",
        rebatePercentage: "0.00",
        shippingFee: 0,
        tax: 0,
        totalDueToday: 0,
        totalDueTodayAfterRebate: "0.00"
      };
      this.pricingDataOffice.rightLensCalculations = JSON.parse(JSON.stringify(this.pricingDataPatient.rightLensCalculations));
    }

    console.log("pricingDataOffice", this.pricingDataOffice);
  }
  checkMultiLensFields() {
    var fields = ["adds", "axis", "baseCurves", "colors", "cylinders", "diameters", "lensCounts", "spheres"];

    if (this.item.leftPrescriptionMultiField) {

      this.eyeDataPatient.leftPrescriptionMultiField = fields.reduce((obj, field) => ({
        ...obj,
        [field]: this.item.leftPrescriptionMultiField.includes(field)
      }), {});
      this.eyeDataOffice.leftPrescriptionMultiField = fields.reduce((obj, field) => ({
        ...obj,
        [field]: this.item.leftPrescriptionMultiField.includes(field)
      }), {});
      console.log("leftPrescriptionMultiField", this.eyeDataPatient.leftPrescriptionMultiField);
    }
    if (this.item.rightPrescriptionMultiField) {
      this.eyeDataPatient.rightPrescriptionMultiField = fields.reduce((obj, field) => ({
        ...obj,
        [field]: this.item.rightPrescriptionMultiField.includes(field)
      }), {});
      this.eyeDataOffice.rightPrescriptionMultiField = fields.reduce((obj, field) => ({
        ...obj,
        [field]: this.item.rightPrescriptionMultiField.includes(field)
      }), {});
      console.log("rightPrescriptionMultiField", this.eyeDataPatient.rightPrescriptionMultiField);
    }
  }

  openNotes() {
    this.bsModalRef.hide();
    // this.modalRef = this.modalService.show(NoteViewComponent, {
    //   class: 'modal-md denial-popup-modal modal-dialogue-centered',
    //   initialState: {
    //     // patientDetails: this.item
    //   }
    // });
  }
  onRemovePrescription() {
    Swal.fire({
      title: `Are you sure?`,
      text: `If you delete this prescription uploaded by the patient, you will need to upload a different one to proceed.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#fa5f55',
      confirmButtonText: 'Yes',
      cancelButtonColor: '#9393934d',
      cancelButtonText: 'No',

    }).then((result) => {
      console.log("result", result)
      if (result.isConfirmed) {
        this.prescriptionUrl = null
        this.precriptionName = null
      }
    })
  }


  onAttachFile(event) {
    this.prescriptionName = this.item.patientId.Patient_Last_Name;
    const file = event.target.files[0];
    const MAX_PDF_PAGES = 10;
    const MAX_FILE_SIZE = 4.5 * 1024 * 1024; // 4.5 MB
    const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];

    // Validate file type
    if (!ALLOWED_TYPES.includes(file.type)) {
      this.errorMsg = 'Only JPG, PNG, and PDF files are allowed.';
      return;
    }

    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      this.errorMsg = 'File size should not exceed 4.5 MB.';
      return;
    }

    // Handle PDF-specific validation
    if (file.type === 'application/pdf') {
      const fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const pageMatches = (e.target.result as string).match(/\/Type\s*\/Page\b/g);
        if ((pageMatches?.length || 0) > MAX_PDF_PAGES) {
          this.errorMsg = 'PDF should not exceed 10 pages.';
          return;
        }
        this.uploadFile(file); // Proceed with upload after validation
      };
      fileReader.readAsText(file); // Basic text-based page count estimation
      return; // Prevent proceeding until PDF validation is complete
    }

    this.uploadFile(file); // Upload other file types directly
  }

  getPrescriptionName() {
    const today = new Date();
    // Store original name first
    const originalName = this.patientDetails.patientId.Patient_Last_Name;
    // Remove file extension and create new formatted name
    const nameWithoutExtension = originalName.split('.')[0];
    let formattedName = `${nameWithoutExtension}_Rx_Upload_${today.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit'
    }).replace(/\//g, '_')}`;
    this.ePrescriptionName = formattedName;
    return formattedName;


  }
  uploadFile(file) {
    this.errorMsg = '';
    const formData = new FormData();
    formData.append('image', file);

    this.api.postData('common/uploadImage', formData).subscribe(
      res => {
        console.log('File uploaded:', res);
        this.prescriptionUrl = res.original;
        this.getPrescriptionName();
      },
      () => {
        this.errorMsg = 'Error uploading file. Please try again.';
      }
    );
  }
  getInsurranceCarrier() {
    this.apiPatientReorder.getData('/arrellio/insurance/drop', {}).subscribe((res: any) => {
      console.log("res getInsurranceCarrier", res);
      this.insurranceCarrierList = res.data;
      if(this.Soft_CL_Insurance_Carrier_Office=="Other"){
        this.inssuranceCarrierImage="https://assets.arrellio.com/uploads/1741772520862sheild_logo.svg"
      }else{
        this.inssuranceCarrierImage = this.insurranceCarrierList.find(item => item.title == this.Soft_CL_Insurance_Carrier_Office)?.brandLogo.original;
      }
    })
  }


  getChargeMessage() {
    let input: any = {};
    input.type = 'charge'
    this.api.showLoader = false;
    this.api.getData('payment/message', input).subscribe(res => {
      console.log("charge", res)
      this.chargeCommunicationMsg = res.message
    });
  }

  getRefundMessage() {
    let input: any = {};
    input.type = 'refund'
    this.api.showLoader = false;
    this.api.getData('payment/message', input).subscribe(res => {
      console.log("refund", res)
      this.refundCommunicationMsg = res.message
    });
  }
  openPrescriptionUpload() {
    this.bsModalRef.hide();
    this.modalRef = this.modalService.show(UploadPrescriptionComponent, {
      class: 'modal-md denial-popup-modal modal-dialog modal-dialog-centered',
      initialState: {
        patientDetails: this.item
      }
    });
  }

  openDenialPopup() {
    this.bsModalRef.hide();
    this.modalRef = this.modalService.show(PrescriptionDenialComponent, {
      class: 'modal-md denial-popup-modal modal-dialog modal-dialog-centered',
      initialState: {
        jobDetails: this.item
      }
    });
  }

  backupEyeData() {
    if (this.item.Soft_CL_Lens_Left_Manufacturer) {
      this.eyeDataPatient.left.manufacturerName = this.item.Soft_CL_Lens_Left_Manufacturer;
      this.eyeDataPatient.left.contactLensSeriesCode = this.item.Soft_CL_Lens_Left_Series_code;
      this.eyeDataPatient.left.add = this.item.Soft_CL_Lens_Left_Add || "N/A";
      this.eyeDataPatient.left.axisName = this.item.Soft_CL_Lens_Left_Axis || "N/A";
      this.eyeDataPatient.left.baseCurveName = this.item.Soft_CL_Lens_Left_Base || "N/A";
      this.eyeDataPatient.left.color = this.item.Soft_CL_Lens_Left_Color || "N/A";
      this.eyeDataPatient.left.cylinder = this.item.Soft_CL_Lens_Left_Cylinder || "N/A";
      this.eyeDataPatient.left.diameter = this.item.Soft_CL_Lens_Left_Diameter || "N/A";
      this.eyeDataPatient.left.image = this.item.Soft_CL_Lens_Left_Image;
      this.eyeDataPatient.left.quantity = this.item.Soft_CL_Lens_Left_Quantity || "N/A";
      this.eyeDataPatient.left.sphere = this.item.Soft_CL_Lens_Left_Sphere || "N/A";
      this.eyeDataPatient.left.style = this.item.Soft_CL_Lens_Left_Style || "N/A";
      this.eyeDataPatient.left.upc = this.item.Soft_CL_Lens_Left_UPC;
      // this.eyeDataPatient.left.sku = this.item.leftSKU;
    }

    if (this.item.Soft_CL_Lens_Right_Manufacturer) {
      this.eyeDataPatient.right.manufacturerName = this.item.Soft_CL_Lens_Right_Manufacturer;
      this.eyeDataPatient.right.contactLensSeriesCode = this.item.Soft_CL_Lens_Right_Series_code || "N/A";
      this.eyeDataPatient.right.add = this.item.Soft_CL_Lens_Right_Add || "N/A";
      this.eyeDataPatient.right.axisName = this.item.Soft_CL_Lens_Right_Axis || "N/A";
      this.eyeDataPatient.right.baseCurveName = this.item.Soft_CL_Lens_Right_Base || "N/A";
      this.eyeDataPatient.right.color = this.item.Soft_CL_Lens_Right_Color || "N/A";
      this.eyeDataPatient.right.cylinder = this.item.Soft_CL_Lens_Right_Cylinder || "N/A";
      this.eyeDataPatient.right.diameter = this.item.Soft_CL_Lens_Right_Diameter || "N/A";
      this.eyeDataPatient.right.image = this.item.Soft_CL_Lens_Right_Image || "N/A";
      this.eyeDataPatient.right.quantity = this.item.Soft_CL_Lens_Right_Quantity || "N/A";
      this.eyeDataPatient.right.sphere = this.item.Soft_CL_Lens_Right_Sphere || "N/A";
      this.eyeDataPatient.right.style = this.item.Soft_CL_Lens_Right_Style || "N/A";
      this.eyeDataPatient.right.upc = this.item.Soft_CL_Lens_Right_UPC;
      // this.eyeDataPatient.right.sku = this.item.rightSKU;
    }
    if (!this.item.Soft_CL_Lens_Left_Manufacturer) {
      this.lockLeftEye = true
    }
    if (!this.item.Soft_CL_Lens_Right_Manufacturer) {
      this.lockRightEye = true
    }
    console.log("lockLeftEye", this.lockLeftEye)
    console.log("lockRightEye", this.lockRightEye)
    console.log("eyeDataPatient", this.eyeDataPatient);
    this.manageEyeData();
    this.eyeDataOffice = JSON.parse(JSON.stringify(this.eyeDataPatient));

  }

  back() {
    this.bsModalRef.hide();
  }


  setEyeType(eyeType: any) {
    this.eyeType = eyeType;
  }

  manageEyeData() {
    if (this.eyeDataOffice.left && this.eyeDataOffice.right) {
      this.eyeType = 'left';
      this.getManufacturers('left');
      return;
    }
    if (this.eyeDataOffice.left) {
      this.eyeType = 'left';
      this.getManufacturers('left');
      return;
    }
    if (this.eyeDataOffice.right) {
      this.eyeType = 'right';
      this.getManufacturers('right');
      return;
    }
  } s


  getManufacturers(eyeType: any) {
    let input: any = {};
    input.distributor = this.api.user.systemId;
    this.apiCatalog.getData('quote/manufacturers', input).subscribe(res => {
      // if(eyeType=='left'){
      this.eyeDataOffice.left.manufacturers = res['data'];
      // }else{
      this.eyeDataOffice.right.manufacturers = res['data'];
      // }
      return false;
    }, error => {
      return false;
    });
  }
  selectManufacturer(item: any, isEdit: any, eyeType: any) {
    console.log("eyeType>>>", this.eyeType)
    if (this.eyeType == 'left') {
      this.eyeDataOffice.left.manufacturerName = item.arrellioManufacturerName;
      if (isEdit) {
        this.eyeDataOffice.left.style = "";
        this.eyeDataOffice.left.baseCurveName = "";
        this.eyeDataOffice.left.diameter = "";
        this.eyeDataOffice.left.sphere = "";
        this.eyeDataOffice.left.cylinder = "";
        this.eyeDataOffice.left.axisName = "";
        this.eyeDataOffice.left.add = "";
        this.eyeDataOffice.left.color = "";
        this.eyeDataOffice.left.quantity = "";
      }
    } else if (this.eyeType == 'right') {
      this.eyeDataOffice.right.manufacturerName = item.arrellioManufacturerName;
      if (isEdit) {
        this.eyeDataOffice.right.style = "";
        this.eyeDataOffice.right.baseCurveName = "";
        this.eyeDataOffice.right.diameter = "";
        this.eyeDataOffice.right.sphere = "";
        this.eyeDataOffice.right.cylinder = "";
        this.eyeDataOffice.right.axisName = "";
        this.eyeDataOffice.right.add = "";
        this.eyeDataOffice.right.color = "";
        this.eyeDataOffice.right.quantity = "";
      }
    }

    this.getSeries(eyeType);
  }

  getSeries(eyeType: any) {
    let input: any = {};
    input.distributor = this.api.user.systemId
    if (eyeType == 'left') {
      input.manufacturerName = this.eyeDataOffice.left.manufacturerName
    } else if (eyeType == 'right') {
      input.manufacturerName = this.eyeDataOffice.right.manufacturerName
    }
    this.api.showLoader = false;
    this.apiCatalog.postData('quote/series', input).subscribe(res => {
      if (eyeType == 'left') {
        this.eyeDataOffice.left.styles = res['data'];
      } else if (eyeType == 'right') {
        this.eyeDataOffice.right.styles = res['data'];
      }
      if (res['data'].length == 1) {
        this.selectSeries(res['data'][0], eyeType);
      }

      return false;
    }, error => {
      return false;
    });
  }

  selectSeries(item: any, eyeType: any) {
    if (eyeType == 'left') {
      this.eyeDataOffice.left.style = item.arrellioContactLensSeriesName;
      this.eyeDataOffice.left.contactLensSeriesCode = item.contactLensSeriesCode;
    } else if (eyeType == 'right') {
      this.eyeDataOffice.right.style = item.arrellioContactLensSeriesName;
      this.eyeDataOffice.right.contactLensSeriesCode = item.contactLensSeriesCode;
    }
    this.getBases(eyeType);
  }

  getBases(eyeType: any) {
    let input: any = {};
    input.distributor = this.api.user.systemId;
    input.manufacturerName = eyeType == "left" ? this.eyeDataOffice.left.manufacturerName : this.eyeDataOffice.right.manufacturerName;
    input.contactLensSeriesCode = eyeType == "left" ? this.eyeDataOffice.left.contactLensSeriesCode : this.eyeDataOffice.right.contactLensSeriesCode;
    this.api.showLoader = false;
    this.apiCatalog.postData('quote/baseCurveName', input).subscribe(res => {
      if (eyeType == 'left') {
        this.eyeDataOffice.left.baseCurves = res['data'];
      } else if (eyeType == 'right') {
        this.eyeDataOffice.right.baseCurves = res['data'];
      }
      if (res['data'].length == 1) {
        this.selectBaseCurveName(res['data'][0], false, eyeType);
      }
    }, error => {
      return false;
    });
  }

  selectBaseCurveName(item: any, isEdit: any, eyeType: any) {
    if (eyeType == 'left') {
      this.eyeDataOffice.left.baseCurveName = item.baseCurveName;
      if (isEdit) {
        this.eyeDataOffice.left.sphere = "";
        this.eyeDataOffice.left.cylinder = "";
        this.eyeDataOffice.left.axisName = "";
        this.eyeDataOffice.left.add = "";
        this.eyeDataOffice.left.color = "";
        this.eyeDataOffice.left.quantity = "";
        this.eyeDataOffice.left.upc = "";
        this.eyeDataOffice.left.sku = "";
      }
    } else if (eyeType == 'right') {
      this.eyeDataOffice.right.baseCurveName = item.baseCurveName;
      if (isEdit) {
        this.eyeDataOffice.right.sphere = "";
        this.eyeDataOffice.right.cylinder = "";
        this.eyeDataOffice.right.axisName = "";
        this.eyeDataOffice.right.add = "";
        this.eyeDataOffice.right.color = "";
        this.eyeDataOffice.right.quantity = "";
        this.eyeDataOffice.right.upc = "";
        this.eyeDataOffice.right.sku = "";
      }
    }
    this.getDiameter(eyeType);
  }

  getDiameter(eyeType: any) {
    this.api.showLoader = false;
    this.loading[eyeType].diameter = true;
    let input: any = {};
    input.distributor = this.api.user.systemId
    input.manufacturerName = eyeType == "left" ? this.eyeDataOffice.left.manufacturerName : this.eyeDataOffice.right.manufacturerName;
    input.contactLensSeriesCode = eyeType == "left" ? this.eyeDataOffice.left.contactLensSeriesCode : this.eyeDataOffice.right.contactLensSeriesCode;
    input.baseCurveName = eyeType == 'left' ? this.eyeDataOffice.left.baseCurveName : this.eyeDataOffice.right.baseCurveName;

    this.apiCatalog.postData('quote/diameter', input).subscribe(res => {
      if (eyeType == 'left') {
        this.eyeDataOffice.left.diameters = res['data'];
      } if (eyeType == 'right') {
        this.eyeDataOffice.right.diameters = res['data'];
      }
      if (res['data'].length == 1) {
        this.selectDiameter(res['data'][0], false, eyeType);
      }
      this.loading[eyeType].diameter = false;
    }, error => {
      return false;
    });
  }

  selectDiameter(item: any, isEdit: any, eyeType: any) {
    if (eyeType == 'left') {
      this.eyeDataOffice.left.diameter = item.diameter;
      if (isEdit) {
        this.eyeDataOffice.left.sphere = "";
        this.eyeDataOffice.left.cylinder = "";
        this.eyeDataOffice.left.axisName = "";
        this.eyeDataOffice.left.add = "";
        this.eyeDataOffice.left.color = "";
        this.eyeDataOffice.left.quantity = "";
        this.eyeDataOffice.left.upc = "";
        this.eyeDataOffice.left.sku = "";
      }
    } else if (eyeType == 'right') {
      this.eyeDataOffice.right.diameter = item.diameter;
      if (isEdit) {
        this.eyeDataOffice.right.sphere = "";
        this.eyeDataOffice.right.cylinder = "";
        this.eyeDataOffice.right.axisName = "";
        this.eyeDataOffice.right.add = "";
        this.eyeDataOffice.right.color = "";
        this.eyeDataOffice.right.quantity = "";
        this.eyeDataOffice.right.upc = "";
        this.eyeDataOffice.right.sku = "";
      }
    }
    this.getSpheres(eyeType);
  }

  getSpheres(eyeType: any) {
    this.api.showLoader = false;
    this.loading[eyeType].sphere = true;
    let input: any = {};
    input.distributor = this.api.user.systemId
    input.manufacturerName = eyeType == "left" ? this.eyeDataOffice.left.manufacturerName : this.eyeDataOffice.right.manufacturerName;
    input.contactLensSeriesCode = this.eyeType == "left" ? this.eyeDataOffice.left.contactLensSeriesCode : this.eyeDataOffice.right.contactLensSeriesCode;
    input.baseCurveName = eyeType == 'left' ? this.eyeDataOffice.left.baseCurveName : this.eyeDataOffice.right.baseCurveName;
    input.diameter = eyeType == 'left' ? this.eyeDataOffice.left.diameter + '' : this.eyeDataOffice.right.diameter + '';

    this.apiCatalog.postData('quote/sphere', input).subscribe(res => {
      if (eyeType == 'left') {
        this.eyeDataOffice.left.spheres = res['data'];
      } else if (eyeType == 'right') {
        this.eyeDataOffice.right.spheres = res['data'];
      }
      if (res['data'].length == 1) {
        this.selectSphere(res['data'][0], false, eyeType);
      }
      this.loading[eyeType].sphere = false;
    }, error => {
      return false;
    });
  }

  selectSphere(item: any, isEdit: any, eyeType: any) {
    console.log("eyeDataPatient", this.eyeDataPatient)
    console.log("eyeType", this.eyeType)
    if (eyeType == 'left') {
      this.eyeDataOffice.left.sphere = item.sphere;
      if (isEdit) {
        this.eyeDataOffice.left.cylinder = "";
        this.eyeDataOffice.left.axisName = "";
        this.eyeDataOffice.left.add = "";
        this.eyeDataOffice.left.color = "";
        this.eyeDataOffice.left.quantity = "";
        this.eyeDataOffice.left.upc = "";
        this.eyeDataOffice.left.sku = "";
      }
    } else if (eyeType == 'right') {
      this.eyeDataOffice.right.sphere = item.sphere;
      if (isEdit) {
        this.eyeDataOffice.right.cylinder = "";
        this.eyeDataOffice.right.axisName = "";
        this.eyeDataOffice.right.add = "";
        this.eyeDataOffice.right.color = "";
        this.eyeDataOffice.right.quantity = "";
        this.eyeDataOffice.right.upc = "";
        this.eyeDataOffice.right.sku = "";
      }
    }
    this.getCylinders(eyeType);
  }

  getCylinders(eyeType: any) {
    console.log("eyeType getCylinders", eyeType)
    this.api.showLoader = false;
    this.loading[eyeType].cylinder = true;
    let input: any = {};
    input.distributor = this.api.user.systemId
    input.manufacturerName = eyeType == "left" ? this.eyeDataOffice.left.manufacturerName : this.eyeDataOffice.right.manufacturerName;
    input.contactLensSeriesCode = eyeType == "left" ? this.eyeDataOffice.left.contactLensSeriesCode : this.eyeDataOffice.right.contactLensSeriesCode;
    input.baseCurveName = eyeType == 'left' ? this.eyeDataOffice.left.baseCurveName : this.eyeDataOffice.right.baseCurveName;
    input.diameter = eyeType == 'left' ? this.eyeDataOffice.left.diameter + '' : this.eyeDataOffice.right.diameter + '';
    input.sphere = eyeType == 'left' ? this.eyeDataOffice.left.sphere + '' : this.eyeDataOffice.right.sphere + '';

    this.apiCatalog.postData('quote/cylinder', input).subscribe(res => {
      if (this.eyeType == 'left') {
        this.eyeDataOffice.left.cylinders = res['data'];
      } else if (this.eyeType == 'right') {
        this.eyeDataOffice.right.cylinders = res['data'];
      }
      if (res['data'].length == 1) {
        this.selectCylinder(res['data'][0], false, eyeType);
      }
      this.loading[eyeType].cylinder = false;
    }, error => {
      return false;
    });
  }

  selectCylinder(item: any, isEdit: any, eyeType: any) {
    if (this.eyeType == 'left') {
      this.eyeDataOffice.left.cylinder = item.cylinder;
      if (isEdit) {
        this.eyeDataOffice.left.axisName = "";
        this.eyeDataOffice.left.add = "";
        this.eyeDataOffice.left.color = "";
        this.eyeDataOffice.left.quantity = "";
        this.eyeDataOffice.left.upc = "";
        this.eyeDataOffice.left.sku = "";
      }
    } else if (this.eyeType == 'right') {
      this.eyeDataOffice.right.cylinder = item.cylinder;
      if (isEdit) {
        this.eyeDataOffice.right.axisName = "";
        this.eyeDataOffice.right.add = "";
        this.eyeDataOffice.right.color = "";
        this.eyeDataOffice.right.quantity = "";
        this.eyeDataOffice.right.upc = "";
        this.eyeDataOffice.right.sku = "";
      }
    }
    this.getAxis(eyeType);
  }

  getAxis(eyeType: any) {
    this.api.showLoader = false;
    this.loading[eyeType].axis = true;
    let input: any = {};
    input.distributor = this.api.user.systemId
    input.manufacturerName = eyeType == "left" ? this.eyeDataOffice.left.manufacturerName : this.eyeDataOffice.right.manufacturerName;
    input.contactLensSeriesCode = eyeType == "left" ? this.eyeDataOffice.left.contactLensSeriesCode : this.eyeDataOffice.right.contactLensSeriesCode;
    input.baseCurveName = eyeType == 'left' ? this.eyeDataOffice.left.baseCurveName : this.eyeDataOffice.right.baseCurveName;
    input.diameter = eyeType == 'left' ? this.eyeDataOffice.left.diameter + '' : this.eyeDataOffice.right.diameter + '';
    input.sphere = eyeType == 'left' ? this.eyeDataOffice.left.sphere + '' : this.eyeDataOffice.right.sphere + '';
    input.cylinder = eyeType == 'left' ? this.eyeDataOffice.left.cylinder + '' : this.eyeDataOffice.right.cylinder + '';

    this.apiCatalog.postData('quote/axis', input).subscribe(res => {
      if (eyeType == 'left') {
        this.eyeDataOffice.left.axis = res['data'];
      } else if (eyeType == 'right') {
        this.eyeDataOffice.right.axis = res['data'];
      }
      if (res['data'].length == 1) {
        this.selectAxis(res['data'][0], false, eyeType);
      }
      this.loading[eyeType].axis = false;
    }, error => {
      return false;
    });
  }

  selectAxis(item: any, isEdit: any, eyeType: any) {
    if (eyeType == 'left') {
      this.eyeDataOffice.left.axisName = item.axis;
      if (isEdit) {
        this.eyeDataOffice.left.add = "";
        this.eyeDataOffice.left.color = "";
        this.eyeDataOffice.left.quantity = "";
        this.eyeDataOffice.left.upc = "";
        this.eyeDataOffice.left.sku = "";
      }
    } else if (eyeType == 'right') {
      this.eyeDataOffice.right.axisName = item.axis;
      if (isEdit) {
        this.eyeDataOffice.right.add = "";
        this.eyeDataOffice.right.color = "";
        this.eyeDataOffice.right.quantity = "";
        this.eyeDataOffice.right.upc = "";
        this.eyeDataOffice.right.sku = "";
      }
    }
    this.getAdds(eyeType);
  }

  getAdds(eyeType: any) {
    this.api.showLoader = false;
    this.loading[eyeType].add = true;
    let input: any = {};
    input.distributor = this.api.user.systemId
    input.manufacturerName = eyeType == "left" ? this.eyeDataOffice.left.manufacturerName : this.eyeDataOffice.right.manufacturerName;
    input.contactLensSeriesCode = eyeType == "left" ? this.eyeDataOffice.left.contactLensSeriesCode : this.eyeDataOffice.right.contactLensSeriesCode;
    input.baseCurveName = eyeType == "left" ? this.eyeDataOffice.left.baseCurveName : this.eyeDataOffice.right.baseCurveName;
    input.diameter = eyeType == 'left' ? this.eyeDataOffice.left.diameter + '' : this.eyeDataOffice.right.diameter + '';
    input.sphere = eyeType == 'left' ? this.eyeDataOffice.left.sphere + '' : this.eyeDataOffice.right.sphere + '';
    input.cylinder = eyeType == 'left' ? this.eyeDataOffice.left.cylinder + '' : this.eyeDataOffice.right.cylinder + '';
    input.axis = eyeType == 'left' ? this.eyeDataOffice.left.axisName + '' : this.eyeDataOffice.right.axisName + '';

    this.apiCatalog.postData('quote/add', input).subscribe(res => {
      this.loading[this.eyeType].add = false;
      if (eyeType == 'left') {
        this.eyeDataOffice.left.adds = res['data'];
      } else if (eyeType == 'right') {
        this.eyeDataOffice.right.adds = res['data'];
      }
      if (res['data'].length == 1) {
        this.selectAdd(res['data'][0], false, eyeType);
      }
    }, error => {
      return false;
    });
  }

  selectAdd(item: any, isEdit: any, eyeType: any) {
    if (eyeType == 'left') {
      this.eyeDataOffice.left.add = item.add;
      if (isEdit) {
        this.eyeDataOffice.left.color = "";
        this.eyeDataOffice.left.quantity = "";
        this.eyeDataOffice.left.upc = "";
        this.eyeDataOffice.left.sku = "";
      }
    } else if (eyeType == 'right') {
      this.eyeDataOffice.right.add = item.add;
      if (isEdit) {
        this.eyeDataOffice.right.color = "";
        this.eyeDataOffice.right.quantity = "";
        this.eyeDataOffice.right.upc = "";
        this.eyeDataOffice.right.sku = "";
      }
    }
    this.getColors(eyeType);
  }

  getColors(eyeType: any) {
    this.api.showLoader = false;
    this.loading[eyeType].color = true;
    let input: any = {};
    input.distributor = this.api.user.systemId
    input.manufacturerName = eyeType == "left" ? this.eyeDataOffice.left.manufacturerName : this.eyeDataOffice.right.manufacturerName;
    input.contactLensSeriesCode = eyeType == "left" ? this.eyeDataOffice.left.contactLensSeriesCode : this.eyeDataOffice.right.contactLensSeriesCode;
    input.baseCurveName = eyeType == 'left' ? this.eyeDataOffice.left.baseCurveName : this.eyeDataOffice.right.baseCurveName;
    input.diameter = eyeType == 'left' ? this.eyeDataOffice.left.diameter + '' : this.eyeDataOffice.right.diameter + '';
    input.sphere = eyeType == 'left' ? this.eyeDataOffice.left.sphere + '' : this.eyeDataOffice.right.sphere + '';
    input.cylinder = eyeType == 'left' ? this.eyeDataOffice.left.cylinder + '' : this.eyeDataOffice.right.cylinder + '';
    input.axis = eyeType == 'left' ? this.eyeDataOffice.left.axisName + '' : this.eyeDataOffice.right.axisName + '';
    input.add = eyeType == 'left' ? this.eyeDataOffice.left.add + '' : this.eyeDataOffice.right.add + '';

    this.apiCatalog.postData('quote/color', input).subscribe(res => {
      if (eyeType == 'left') {
        this.eyeDataOffice.left.colors = res['data'];
      } else if (eyeType == 'right') {
        this.eyeDataOffice.right.colors = res['data'];
      }
      if (res['data'].length == 1) {
        this.selectColor(res['data'][0], false, eyeType);
      }
      this.loading[eyeType].color = false;
    }, error => {
      return false;
    });
  }

  selectColor(item: any, isEdit: any, eyeType: any) {
    if (eyeType == 'left') {
      this.eyeDataOffice.left.color = item.color;
    } else if (eyeType == 'right') {
      this.eyeDataOffice.right.color = item.color;
    }
    this.getLensCounts(eyeType);
  }

  getLensCounts(eyeType: any) {
    this.api.showLoader = false;
    this.loading[eyeType].lensCount = true;
    let input: any = {};
    input.distributor = this.api.user.systemId
    input.manufacturerName = eyeType == "left" ? this.eyeDataOffice.left.manufacturerName : this.eyeDataOffice.right.manufacturerName;
    input.contactLensSeriesCode = eyeType == "left" ? this.eyeDataOffice.left.contactLensSeriesCode : this.eyeDataOffice.right.contactLensSeriesCode;
    input.baseCurveName = eyeType == 'left' ? this.eyeDataOffice.left.baseCurveName : this.eyeDataOffice.right.baseCurveName;
    input.diameter = eyeType == 'left' ? this.eyeDataOffice.left.diameter + '' : this.eyeDataOffice.right.diameter + '';
    input.sphere = eyeType == 'left' ? this.eyeDataOffice.left.sphere + '' : this.eyeDataOffice.right.sphere + '';
    input.cylinder = eyeType == 'left' ? this.eyeDataOffice.left.cylinder + '' : this.eyeDataOffice.right.cylinder + '';
    input.axis = eyeType == 'left' ? this.eyeDataOffice.left.axisName + '' : this.eyeDataOffice.right.axisName + '';
    input.add = eyeType == 'left' ? this.eyeDataOffice.left.add + '' : this.eyeDataOffice.right.add + '';
    input.color = eyeType == 'left' ? this.eyeDataOffice.left.color + '' : this.eyeDataOffice.right.color + '';

    this.apiCatalog.postData('quote/catalogPackName', input).subscribe(res => {
      if (eyeType == 'left') {
        this.eyeDataOffice.left.quantities = res['data'];
      } else if (eyeType == 'right') {
        this.eyeDataOffice.right.quantities = res['data'];
      }
      if (res['data'].length == 1) {
        this.selectLensCount(res['data'][0], false, eyeType);
      }
      this.loading[this.eyeType].lensCount = false;
    }, error => {
      return false;
    });
  }

  selectLensCount(item: any, isEdit: any, eyeType: any) {
    this.loading[eyeType].lensCount = false;
    if (eyeType == 'left') {
      this.eyeDataOffice.left.quantity = item.catalogPackName;
      this.eyeDataOffice.left.upc = item.upc;
      this.eyeDataOffice.left.sku = item.sku;
    } else if (eyeType == 'right') {
      this.eyeDataOffice.right.quantity = item.catalogPackName;
      this.eyeDataOffice.right.upc = item.upc;
      this.eyeDataOffice.right.sku = item.sku;
    }

    // if (this.eyeType == "left") {
    //   if (this.eyeDataOffice.right) {
    //     this.eyeType = "right";
    //     this.getSeries();
    //     return;
    //   }
    // }
  }

  confirmPrecriptionUpload() {
    let input: any = {};
    console.log("this.eyeDataOffice", this.eyeDataOffice);
    input.patientId = this.item.patientId._id;
    if (this.eyeDataOffice.right.sphere) {
      // this.api.updateEyeData('right', this.eyeDataOffice.right);
      input.od = {
        SPH: this.eyeDataOffice.right.sphere + "",
        CYL: this.eyeDataOffice.right.cylinder + "",
        AXIS: this.eyeDataOffice.right.axisName + "",
        ADD: this.eyeDataOffice.right.add + "",
        DIAM: this.eyeDataOffice.right.diameter + "",
        BC: this.eyeDataOffice.right.baseCurveName + "",
        COLOR: this.eyeDataOffice.right.color + "",
        UNITS: this.eyeDataOffice.right.quantity + "",
        UPC: this.eyeDataOffice.right.upc + "",
        MAN: this.eyeDataOffice.right.manufacturerName + "",
        SERIES: this.eyeDataOffice.right.style + "",
      };
    }

    if (this.eyeDataOffice.left.sphere) {
      // this.api.updateEyeData('left', this.eyeDataOffice.left);
      input.os = {
        SPH: this.eyeDataOffice.left.sphere + "",
        CYL: this.eyeDataOffice.left.cylinder + "",
        AXIS: this.eyeDataOffice.left.axisName + "",
        ADD: this.eyeDataOffice.left.add + "",
        DIAM: this.eyeDataOffice.left.diameter + "",
        BC: this.eyeDataOffice.left.baseCurveName + "",
        COLOR: this.eyeDataOffice.left.color + "",
        UNITS: this.eyeDataOffice.left.quantity + "",
        UPC: this.eyeDataOffice.left.upc + "",
        MAN: this.eyeDataOffice.left.manufacturerName + "",
        SERIES: this.eyeDataOffice.left.style + ""
      };
    }

    input.prescriptionStartDate = this.prescription.Soft_CL_Associated_Rx_Start_Date;
    input.prescriptionEndDate = this.prescription.Soft_CL_Associated_Rx_Expiration_Date;
    input.empName = this.prescription.Soft_CL_Associated_Rx_Doctor
    input.officeName = this.prescription.Soft_CL_Associated_Rx_Office_Name;
    input.uploadedBy = 'office';
    console.log("input>>>>", input);

    this.api.postData('ecommerce/prescription', input).subscribe(res => {
      this.prescription = res.data
      this.updateJobData()
    });
  }

  copyLeftToRight() {
    this.eyeDataOffice.right = this.eyeDataOffice.left;
  }

  copyRightToLeft() {
    this.eyeDataOffice.left = this.eyeDataOffice.right;
  }

  selectLens() {
    this.bsModalRef.hide();
    // this.modalRef = this.modalService.show(SearchLensComponent, {
    //   class: 'modal-md select-lens-modal modal-dialogue-centered',
    // });
  }

  validateFields() {
    if (this.eyeType == "left") {
      if (this.eyeDataOffice?.left?.color) {
        return true;
      } else {
        return false;
      }
    } else if (this.eyeType == "right") {
      if (this.eyeDataOffice?.right?.color) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getFinancialData() {
    console.log("officeData", this.eyeDataOffice);
    let input: any = {};
    if (this.eyeDataOffice.left.upc) {
      input.leftUPC = encodeURIComponent(this.eyeDataOffice.left.upc);
      // input.leftLensName = this.eyeDataOffice.left.style
    }
    if(this.eyeDataOffice.left.style){
      input.leftLensName = this.eyeDataOffice.left.style
    }
    if (this.eyeDataOffice.right.upc) {
      input.rightUPC = encodeURIComponent(this.eyeDataOffice.right.upc);
    }
    if(this.eyeDataOffice.right.style){
      input.rightLensName = this.eyeDataOffice.right.style
    }
    if (this.item.annualDiscountSelection !== undefined && this.item.annualDiscountSelection !== null) {
      input.annualDiscountShouldBeApplied = this.item.annualDiscountShouldBeApplied
    }
    if (this.insuranceApplied_Office) {
      input.isInsuranceApplyed = this.insuranceApplied_Office
    }

    if (this.officeInsuranceAllowance) {
      input.insuranceAllowance = this.officeInsuranceAllowance
    }
    if (this.officeInsuranceCopay) {
      input.insuranceCopay = this.officeInsuranceCopay
    }
    if (this.outOfNetworkType) {
      input.outOfNetworkType = this.outOfNetworkType
    }
    if(this.item.updatedData?.couponCode){
      input.couponCode=this.item.updatedData?.couponCode
    }
    //  if(this.insuranceType){
    //   input.insuranceType=this.insuranceType
    //  }
    if (this.officeInsurranceDiscount) {
      input.overageCoInsurance = this.officeInsurranceDiscount
    }
    if (this.formularyLens) {
      input.formularyLens = this.formularyLens
    }
    input.deliveryType = this.item.deliveryTime
    input.isDirectToOffice = this.item.shipType == 'DTO' ? true : false
    input.supply = this.item.eSupply
    if(this.item.rebateSelection){
      input.rebateStatus = this.item.rebateSelection
    }
    input.storeId = this.item.storeId._id
    input.patientId = this.item.patientId._id

    console.log("input", input);
    this.api.getData('ecommerce/cart-details', input).subscribe(res => {
      console.log("res", res);

      this.pricingTotalOffice = res.data.pricing

      let pricing = {
        leftLensCalculations: res.data.leftEye,
        rightLensCalculations: res.data.rightEye
      }

      this.pricingDataOffice = pricing
      this.couponDetails=res.data.couponDetail
      if (!pricing.leftLensCalculations) {
        pricing.leftLensCalculations = {
          price: "0.00",
          grossPrice: 0,
          quantity: "0",
          afterCouponDiscountPrice: 0,
          afterDiscountAndRebatePrice: 0,
          afterRebatePrice: 0,
          couponDiscount: 0,
          couponDiscountPercentage: "0.00",
          discount: 0,
          insuranceAllowance: 0,
          insuranceCopay: 0,
          leftEyeDiscountAnnualSavings: 0,
          leftEyeDiscountAnnualSavingsPercentage: 0,
          overageCoInsurance: 0,
          processingFee: "0.00",
          rebate: "0",
          rebatePercentage: "0.00",
          shippingFee: 0,
          tax: 0,
          totalDueToday: 0,
          totalDueTodayAfterRebate: "0.00"
        };
        this.pricingDataOffice.leftLensCalculations = JSON.parse(JSON.stringify(pricing.leftLensCalculations));
      }

      if (!pricing.rightLensCalculations) {
        pricing.rightLensCalculations = {
          price: "0.00",
          grossPrice: 0,
          quantity: "0",
          afterCouponDiscountPrice: 0,
          afterDiscountAndRebatePrice: 0,
          afterRebatePrice: 0,
          couponDiscount: 0,
          couponDiscountPercentage: "0.00",
          discount: 0,
          insuranceAllowance: 0,
          insuranceCopay: 0,
          overageCoInsurance: 0,
          rightEyeDiscountAnnualSavings: 0,
          rightEyeDiscountAnnualSavingsPercentage: 0,
          processingFee: "0.00",
          rebate: "0",
          rebatePercentage: "0.00",
          shippingFee: 0,
          tax: 0,
          totalDueToday: 0,
          totalDueTodayAfterRebate: "0.00"
        };
          this.pricingDataOffice.rightLensCalculations = JSON.parse(JSON.stringify(pricing.rightLensCalculations));
      }

      this.Soft_CL_Sales_Amount_Office = res.data.pricing.totalDueToday
      this.netDifference = this.item.Soft_CL_Sales_Amount - this.Soft_CL_Sales_Amount_Office
      if(this.netDifference<0){
        this.netDifference=this.netDifference*-1
      }
      console.log("netDiffercence", this.netDifference)
      console.log("pricingDataOffice>>>>", this.pricingDataOffice);
      console.log("pricingDataPatient>>>>", this.pricingDataPatient);
      console.log("Soft_CL_Sales_Amount_Office", this.Soft_CL_Sales_Amount_Office);

      if (this.Soft_CL_Sales_Amount_Office > this.item.Soft_CL_Sales_Amount) {
        this.adjustmentPayment = "charged"
      }
      if (this.Soft_CL_Sales_Amount_Office < this.item.Soft_CL_Sales_Amount) {
        this.adjustmentPayment = "refunded"
      }
      this.setDynamicText()
    })
  }

  setInsuranceType(type: any) {
    this.insuranceType = type;
  }

  setOutOfNetworkType(type: any) {
    this.outOfNetworkType = type;
  }
  setFormularyLens(type: any) {
    this.formularyLens = type;
  }
  formatToTwoDecimalPlaces(event: any) {
    let value = event.target.value;
    if (value) {
      let num = parseFloat(value.replace(/[^0-9.]/g, ''));
      if (!isNaN(num)) {
        event.target.value = num.toFixed(2);
      } else {
        event.target.value = '0.00';
      }
    }
    this.checkHasInsuranceValues();
  }

  setInsuranceCarrier(carrier: any) {
    this.Soft_CL_Insurance_Carrier_Office = carrier;
    this.inssuranceCarrierImage = this.insurranceCarrierList.find(item => item.title == carrier)?.brandLogo.original;
  }

  checkHasInsuranceValues() {
    if (this.officeInsuranceAllowance && this.officeInsuranceCopay && this.officeInsurranceDiscount) {
      this.getFinancialData();
    }
  }


  createCharge() {
    let input: any = {}
    // input.internalNotes = this.chargeInternalNotes
    //   if (this.chargeAdditionalNotes) {
    //     input.additionalNotes = this.chargeAdditionalNotes
    //   }
    input._id = this.item._id;
    input.chargeId = this.item.stripeInvoiceNumber;
    input.amount = Math.abs(this.netDifference).toFixed(2) + '';
    input.enterpriseId = this.api.user.enterpriseId._id
    input.invoiceNumber = this.item.stripeInvoiceNumber;
    input.internalNotes = "test"
    input.Soft_CL_Point_of_Sale_Order_Number = this.item.Soft_CL_Point_of_Sale_Order_Number
    input.jobId = this.item._id
    input.storeId = this.item.storeId._id
    input.jobNumber = this.item._id
    this.api.postData(`payment/charge/create/${this.api.user.enterpriseId.stripeConnectId}`, input).subscribe(
      (res) => {
        console.log("res", res);
        this.toastr.success("Charge created successfully");
        this.bsModalRef.hide();
        // this.setCurrentItem( this.transactions[index],'charge')
      },
      (err) => {
        console.error("Error", err);
        this.api.showLoader = false;
      }
    );
  }

  createRefund() {
    let input: any = {}
    input.chargeId = this.item.stripeInvoiceNumber;
    input.amount = this.netDifference.toFixed(2) + '';
    input.internalNotes = this.refundInternalNotes
    input.reason = "duplicate"
    this.api.postData(`payment/refund/${this.api.user.enterpriseId.stripeConnectId}`, input).subscribe(
      (res) => {
        console.log("res", res);
        this.toastr.success("Refund created successfully");
        this.bsModalRef.hide();
        // this.setCurrentItem( this.transactions[index],'charge')
      },
      (err) => {
        console.error("Error", err);
        this.api.showLoader = false;
      }
    );
  }

  onComplete() {
    this.confirmFinancialInfo = true;
    this.confirmFinancialInfoStepActive = true;
    if (this.adjustmentPayment === 'charged') {
      this.createCharge();
      this.changeJobStatus('Completed');
    }
    else if (this.adjustmentPayment === 'refunded') {
      this.createRefund();
      this.changeJobStatus('Completed');
    }
    else {
      this.changeJobStatus('Completed');
    }
    this.updateJobData()
    setTimeout(()=>{
      this.updateOrderPaymentEmail()
    },1000)
    console.log("this.office", this.eyeDataOffice)
    console.log("this.priceingDataOffice", this.pricingDataOffice)

  }
  updateOrderPaymentEmail(){
    let input:any={}
    input.jobId=this.item._id
    input.storeDetails=this.storeDetails
    input.patientDetails=this.patientDetails
    this.apiNotification.postData('notification/updateOrderPaymentEmail',input,true).subscribe(res=>{
      console.log("res",res);
      this.bsModalRef.hide();
    })
  }

  onConfirmPrescriptionInfo() {
    this.getFinancialData();
    this.confirmFinancialInfo = false;
    this.confirmPatientInfo = true;
    this.confirmPrescriptionInfo = true;
    this.confirmPatientInfoStepActive = true;
    this.confirmPrescriptionInfoStepActive = true;
    console.log("this.eyeDataOffice", this.eyeDataOffice);
    console.log("this.eyeDataPatient", this.eyeDataPatient);
    this.compareStyleAndManufacturerName()
    // const leftMatches = this.deepEqualExcludingKey(this.eyeDataOffice.left, this.eyeDataPatient.left, 'manufacturers');
    // const rightMatches = this.deepEqualExcludingKey(this.eyeDataOffice.right, this.eyeDataPatient.right, 'manufacturers');
    // 'deepEqualExcludingKey' returns true if objects are the same, so we invert that for "changed"
    // Now “isleftPrescriptionChanged” = true if there is a difference
    // this.isleftPrescriptionChanged = !leftMatches;
    // this.isrightPrescriptionChanged = !rightMatches;
    // console.log("this.isleftPrescriptionChanged", this.isleftPrescriptionChanged);
    // console.log("this.isrightPrescriptionChanged", this.isrightPrescriptionChanged);
    // console.log("isLensChanged",this.isleftPrescriptionChanged || this.isrightPrescriptionChanged)
    this.confirmPrecriptionUpload();

  }

  // deepEqualExcludingKey(obj1, obj2, excludeKey) {
  //   const filteredObj1 = { ...obj1 };
  //   const filteredObj2 = { ...obj2 };

  //   delete filteredObj1[excludeKey]; // Remove the key from both objects
  //   delete filteredObj2[excludeKey];

  //   return JSON.stringify(filteredObj1) === JSON.stringify(filteredObj2);
  // }
  compareStyleAndManufacturerName() {
    const leftMatches =
      this.eyeDataOffice.left.style === this.eyeDataPatient.left.style &&
      this.eyeDataOffice.left.manufacturerName === this.eyeDataPatient.left.manufacturerName;
  
    const rightMatches =
      this.eyeDataOffice.right.style === this.eyeDataPatient.right.style &&
      this.eyeDataOffice.right.manufacturerName === this.eyeDataPatient.right.manufacturerName;
  
    // You can then decide what to do with the boolean results
    console.log('Left eyes match on style and manufacturerName?', leftMatches);
    console.log('Right eyes match on style and manufacturerName?', rightMatches);
    this.isleftPrescriptionChanged=!leftMatches
    this.isrightPrescriptionChanged=!rightMatches
  
  }
  onConfirmPatientInfo() {
    this.confirmPatientInfo = true
    this.confirmPatientInfoStepActive = true;
    this.confirmPrescriptionInfo = false;
    console.log("this.patientDetails.eStatus", this.patientDetails.eStatus);
    console.log("this.item.patientId.eStatus", this.item.patientId.eStatus);
    this.updatePatient()

  }

  resetConfirmPatientInfo() {
    this.confirmPatientInfo = false;
    // this.confirmPatientInfoStepActive=false;
    this.confirmPrescriptionInfo = true;
    this.confirmFinancialInfo = true;
  }
  resetConfirmPrescriptionInfo() {
    this.confirmPrescriptionInfo = false;
    // this.confirmPrescriptionInfoStepActive=false;
    this.confirmFinancialInfo = true;
    this.confirmPatientInfo = true;
  }
  resetConfirmFinancialInfo() {
    this.getFinancialData();
    this.confirmFinancialInfo = false;
    // this.confirmFinancialInfoStepActive=false;
    this.confirmPatientInfo = true;
    this.confirmPrescriptionInfo = true;

  }
  changeJobStatus(status: any) {
    let input: any = {}
    input.webstoreTicketStatus = status
    if (status == "Cancelled") {
      input.cancellationNote = this.cancelNotes
      input.cancellationPatientFacingNotes = this.cancellationPatientFacingNote
    }
    this.api.putData(`webstore/job/changeStatus/${this.item._id}`, input).subscribe(res => {
      console.log("res", res);
      this.storeDetails=res.data.storeId
     
      console.log("status>>>.",status);
      if (status == 'Cancelled') {
        this.fullRefund();
        this.denialOrCancelPaymentEmail()
        // this.bsModalRef.hide();
      }
      this.onClose.next(res.data);
      if (status == 'Patient Confirmed by Office') {

      }
      else {
        this.bsModalRef.hide();
        // window.location.reload();

      }

    })
  }


  denialOrCancelPaymentEmail(){
    let input:any={}
    input.jobId=this.item._id
    input.status=''
    input.cancellationPatientFacingNotes=this.cancellationPatientFacingNote
    input.cancellationNote=this.cancelNotes
    input.storeDetails=this.storeDetails
    this.apiNotification.postData('notification/denialOrCancelPaymentEmail',input,true).subscribe(res=>{
      console.log("res",res);
      this.bsModalRef.hide();
      // window.location.reload();
    })
  }


  onCancel() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Canceling this order will issue a refund to the patient, net of the processing fees you incurred, and this action can't be undone.",
      icon: 'warning',
      html: `
        <div class="form-group mb-3">
        <label for="adminNotes">Cancel Patient Facing Notes</label>
        <textarea id="adminNotes" class="swal2-textarea" placeholder="Cancel Patient Facing Notes"></textarea>
        </div>
        <div class="form-group mb-3">
        <label for="patientNotes" style="margin-top: 1rem;">Cancel Internal Notes</label>
        <textarea id="patientNotes" class="swal2-textarea" placeholder="Cancel Internal Notes"></textarea>
        </div>
      `,
      customClass: {
        container: 'sweet-alert-container',
        actions: 'flex-row-reverse',
      },
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#45b95a',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      preConfirm: () => {
        const adminNotes = (document.getElementById('adminNotes') as HTMLTextAreaElement).value;
        const patientNotes = (document.getElementById('patientNotes') as HTMLTextAreaElement).value;

        if (!adminNotes || !patientNotes) {
          Swal.showValidationMessage('Both fields are required');
          return false;
        }
        return { adminNotes, patientNotes };
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const { adminNotes, patientNotes } = result.value;
        this.cancelNotes = adminNotes;
        this.cancellationPatientFacingNote = patientNotes;
        console.log('Cancel Notes:', adminNotes);
        console.log('Cancel Patient-Facing Notes:', patientNotes);
        this.changeJobStatus('Cancelled');
      }
    });
  }
  fullRefund() {
    let input: any = {}
    input.chargeId = this.item.stripeInvoiceNumber;
    input.amount = (this.item.Soft_CL_Sales_Amount - this.stripeProcessingFee) + '';
    input.internalNotes = "full refund"
    input.reason = "duplicate"
    this.api.postData(`payment/refund/${this.api.user.enterpriseId.stripeConnectId}`, input).subscribe(
      (res) => {
        console.log("res", res);
        // this.toastr.success("Refund created successfully");
        this.bsModalRef.hide();
        // window.location.reload();
        // this.setCurrentItem( this.transactions[index],'charge')
      },
      (err) => {
        console.error("Error", err);
        this.api.showLoader = false;
      }
    );
  }
  openPrescriptionDelete() {
    this.bsModalRef.hide();
    this.modalRef = this.modalService.show(PrescrptionDeletionComponent, {
      class: 'modal-md select-lens-modal modal-dialog modal-dialog-centered',
    });
  }

  setInsurranceField(field: any) {
    console.log("field", field);
    this.insurranceFieldType = field;
      this.resetInsurranceField()
  }
  resetInsurranceField(){
    this.officeInsuranceAllowance=""
    this.officeInsuranceCopay=""
    this.officeInsurranceDiscount=""
    this.adjustmentPayment=""
    this.dynamicText=""
    this.getFinancialData()
  }
  setOfficeEditedPatientStatus(status: any) {
    this.eStatusByOffice = status;
  }


  updatePatient() {
    console.log("this.item121", this.item);
    let input: any = {}
    input.eStatus = this.patientDetails.eStatus
    input.Patient_Point_of_Sale_Patient_ID = this.patientDetails.Patient_Point_of_Sale_Patient_ID
    input.eStatusByOffice = this.eStatusByOffice;
    this.api.putData(`ecommerce/patient/${this.patientDetails._id}`, input).subscribe(
      (res) => {
        console.log("res", res);
        this.changeJobStatus('Patient Confirmed by Office')
      },
      (err) => {
        console.error("Error", err);
        this.api.showLoader = false;
      });

  }
  checkFields = (eye, fieldsArray) => {

    const multiField = [];
    if (!eye?.manufacturerName) return [];

    fieldsArray.forEach(field => {
      if (eye[field]?.length > 1) {
        multiField.push(field);
      }
    });

    return multiField;
  };
  updateJobData() {
    let input: any = {}
    var eyeData = this.eyeDataOffice;
    if (eyeData !== null || eyeData !== undefined) {
      const fields = ["adds", "axis", "baseCurves", "colors", "cylinders", "diameters", "lensCounts", "spheres"];
      input.leftPrescriptionMultiField = this.checkFields(eyeData?.left, fields);
      input.rightPrescriptionMultiField = this.checkFields(eyeData?.right, fields);
    }
    input.rightLens = this.eyeDataOffice.right;
    input.leftLens = this.eyeDataOffice.left;
    input.leftLensCalculations=this.pricingDataOffice.leftLensCalculations;
    input.rightLensCalculations=this.pricingDataOffice.rightLensCalculations;
    input.pricing = this.pricingTotalOffice;
    input.Soft_CL_Insurance_Carrier=this.Soft_CL_Insurance_Carrier_Office

    input.updatedData={
      adjustmentType:this.adjustmentPayment,
      adjustmentAmount:this.netDifference,
      Soft_CL_Sales_Amount_Office:this.Soft_CL_Sales_Amount_Office,
      Soft_CL_Sales_Amount_Patient:this.item.Soft_CL_Sales_Amount,
      oldLensDetailLeft:this.eyeDataPatient.left,
      oldLensDetailRight:this.eyeDataPatient.right,
      oldInsurranceCarrier:this.Soft_CL_Insurance_Carrier_Patient,
      insuranceField:this.insurranceFieldType,
      isLensChanged:this.isleftPrescriptionChanged || this.isrightPrescriptionChanged,
      isLeftLensChanged:this.isleftPrescriptionChanged,
      isRightLensChanged:this.isrightPrescriptionChanged,
      oldTotalDueToday:this.item.Soft_CL_Sales_Amount
    }
    

    input.amount = this.Soft_CL_Sales_Amount_Office;
    console.log("this.prescription>>12", this.prescription);
    if (this.patientDetails.eStatus == 'new') {
      input.prescriptionId = this.prescription.prescriptionId
      input.empName = this.prescription.Soft_CL_Associated_Rx_Doctor
      input.officeName = this.prescription.Soft_CL_Associated_Rx_Office_Name
      input.prescriptionStartDate = this.prescription.Soft_CL_Associated_Rx_Start_Date
      input.prescriptionEndDate = this.prescription.Soft_CL_Associated_Rx_Expiration_Date
    }
    console.log("input", input);
    let inputData = {
      jobData: input,
    }
    this.api.putData(`jobs/ecommerceJob/${this.item._id}`, inputData).subscribe(
      (res) => {
        console.log("res", res);
      },
      (err) => {
        console.error("Error", err);
        this.api.showLoader = false;
      }
    );
  }


  disablePatientInfoConfirm(){
    return this.item.webstoreTicketStatus=='Hold Prescription' || this.item.webstoreTicketStatus=='Hold Financial Data' || this.item.webstoreTicketStatus=='Denied' || this.item.webstoreTicketStatus=='Completed' || this.item.webstoreTicketStatus=='Cancelled' || this.patientDetails.eStatus=='existing'
  }


  
  disableRxVerify(){
    return this.item.webstoreTicketStatus ==='Completed' || this.item.webstoreTicketStatus ==='Denied'|| this.item.webstoreTicketStatus ==='Cancelled' || !this.confirmPatientInfoStepActive || this.patientDetails.eStatus=='existing'
  }

  disableRxHold(){
    return this.item.webstoreTicketStatus ==='Completed' || this.item.webstoreTicketStatus ==='Denied'|| this.item.webstoreTicketStatus ==='Cancelled' || !this.confirmPatientInfoStepActive || this.patientDetails.eStatus=='existing'
  }

  disableRxDeny(){
    return this.item.webstoreTicketStatus ==='Completed' || this.item.webstoreTicketStatus ==='Denied'|| this.item.webstoreTicketStatus ==='Cancelled' || !this.confirmPatientInfoStepActive || this.patientDetails.eStatus=='existing'
  }
 
  disableFinancialCancel(){
    return this.item.webstoreTicketStatus =='Completed' || this.item.webstoreTicketStatus =='Cancelled' || this.item.webstoreTicketStatus =='Denied' || !this.confirmPatientInfoStepActive || !this.confirmPrescriptionInfoStepActive
  }
  disableFinancialHold(){
    return this.item.webstoreTicketStatus =='Completed' || this.item.webstoreTicketStatus =='Cancelled' || this.item.webstoreTicketStatus =='Denied' || !this.confirmPatientInfoStepActive || !this.confirmPrescriptionInfoStepActive
  }
  disableFinancialComplete(){
    return this.item.webstoreTicketStatus =='Completed' || this.item.webstoreTicketStatus =='Cancelled' || this.item.webstoreTicketStatus =='Denied' || !this.confirmPatientInfoStepActive || !this.confirmPrescriptionInfoStepActive
  }


  setDynamicText() {
    if (this.patientDetails?.eStatus === 'existing') {
      if (this.insurranceFieldType === 'insurranceWithExistingCarrier' && this.adjustmentPayment) {
        this.dynamicText = `Given the patient is eligible for their stated vision insurance plan, there is a net difference of $${this.netDifference?.toFixed(2)} that needs to be charged to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of their coverage and incoming charge.`;
      } else if (this.insurranceFieldType === 'insurranceWithNewCarrier' && this.adjustmentPayment) {
        this.dynamicText = `Given the patient is eligible for the updated vision insurance plan, there is a net difference of $${this.netDifference?.toFixed(2)} that needs to be ${this.adjustmentPayment === 'refunded' ? 'refunded' : 'charged'} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of their coverage and incoming ${this.adjustmentPayment === 'refunded' ? 'refund' : 'charge'}.`;
      } else if (this.insurranceFieldType === 'noInsurance') {
        this.dynamicText = `Given the patient is not eligible for their stated vision insurance plan, there are no changes, financially, needed for this order. Please proceed with completing the order below. We’ll notify the patient of their ineligible status and process the order accordingly.`;
      }
    } else if (this.patientDetails?.eStatus === 'new') {
      if (!this.insuranceApplied_Office && !(this.isleftPrescriptionChanged || this.isrightPrescriptionChanged)) {
        this.dynamicText = `Given there were no changes to the patient's provided prescription and the patient elected to not use insurance, there are no financial changes needed. Please proceed with completing the order below. We'll send a message to the patient, automatically, on your behalf notifying them their order is processing.`;
      } else if (this.insurranceFieldType === 'insurranceWithExistingCarrier' && !(this.isleftPrescriptionChanged || this.isrightPrescriptionChanged) && this.adjustmentPayment) {
        this.dynamicText = `There were no changes to the patient's provided prescription but the patient elected to use insurance. Based on your prompt answers, the patient is eligible for their stated vision insurance plan, resulting in a net difference of $${this.netDifference?.toFixed(2)} that needs to be ${this.adjustmentPayment} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of their coverage and incoming ${this.adjustmentPayment === 'refunded' ? 'refund' : 'charge'}.`;
      } else if (this.insurranceFieldType === 'insurranceWithNewCarrier' && !(this.isleftPrescriptionChanged || this.isrightPrescriptionChanged) && this.adjustmentPayment) {
        this.dynamicText = `There were no changes to the patient's provided prescription but the patient elected to use insurance. Based on your prompt answers, the patient is eligible for the updated vision insurance plan, resulting in a net difference of $${this.netDifference?.toFixed(2)} that needs to be ${this.adjustmentPayment} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of their coverage and incoming ${this.adjustmentPayment === 'refunded' ? 'refund' : 'charge'}.`;
      } else if (this.insurranceFieldType === 'noInsurance' && !(this.isleftPrescriptionChanged || this.isrightPrescriptionChanged)) {
        this.dynamicText = `There were no changes to the patient's provided prescription but the patient elected to use insurance. Based on your prompt answers, the patient is not eligible for their stated vision insurance plan, and as a result, there are no financial changes needed for this order. Please proceed with completing the order below. We’ll notify the patient of their ineligible status and process the order accordingly.`;
      } else if (!this.insuranceApplied_Office && (this.isleftPrescriptionChanged || this.isrightPrescriptionChanged) && this.adjustmentPayment) {
        this.dynamicText = `The patient elected to not use insurance but there were changes to the patient's provided prescription. The prescription changes resulted in a net difference of $${this.netDifference?.toFixed(2)} that needs to be ${this.adjustmentPayment} to the patient. Please proceed with completing the order below. We'll send a message to the patient, automatically, on your behalf notifying them of the ${this.adjustmentPayment === 'refunded' ? 'refund' : 'charge'}.`;
      } else if (this.insurranceFieldType === 'insurranceWithExistingCarrier' && (this.isleftPrescriptionChanged || this.isrightPrescriptionChanged) && this.adjustmentPayment) {
        this.dynamicText = `There were changes to the patient's provided prescription and the patient elected to use insurance. Based on both changes and your prompt answers, there is a difference of $${this.netDifference?.toFixed(2)} that needs to be ${this.adjustmentPayment} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of the ${this.adjustmentPayment === 'refunded' ? 'refund' : 'charge'}.`;
      } else if (this.insurranceFieldType === 'insurranceWithNewCarrier' && (this.isleftPrescriptionChanged || this.isrightPrescriptionChanged) && this.adjustmentPayment) {
        this.dynamicText = `There were changes to the patient's provided prescription and the patient elected to use insurance. Based on both changes and your prompt answers, there is a difference of $${this.netDifference?.toFixed(2)} that needs to be ${this.adjustmentPayment} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of the ${this.adjustmentPayment === 'refunded' ? 'refund' : 'charge'}.`;
      } else if (this.insurranceFieldType === 'noInsurance' && (this.isleftPrescriptionChanged || this.isrightPrescriptionChanged) && this.adjustmentPayment) {
        this.dynamicText = `There were changes to the patient's provided prescription and the patient elected to use insurance. While the patient is not eligible for their stated vision insurance plan, the prescription changes resulted in a net difference of $${this.netDifference?.toFixed(2)} that needs to be ${this.adjustmentPayment} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of the ${this.adjustmentPayment === 'refunded' ? 'refund' : 'charge'}.`;
      }
    }
  }


}
