 <div class="modal-header justify-content-center">
    <h4 class="modal-title">Prescription Denial</h4>
    <button (click)="closePopup()" type="button" class="btn-close btn" data-bs-dismiss="modal" aria-label="Close">
        <img src="assets/images/orange-close.svg" />
    </button>
</div>
<div class="modal-body p-lg-4">
    <div class="dashboard-form">
        <div class="form-group mb-3">
            <label>Denial Reason</label>
            <div class="dropdown">
                <div class="dropdown store-selection carret-dropdown">
                    <a class="btn text-start form-control select" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{denialReason}}
                    </a>
                    <ul class="dropdown-menu w-100" aria-labelledby="left">
                        <li class="pointer"><div class="dropdown-item" href="javascript:void(0)" (click)="denialReason='Prescription Expired'">Prescription Expired</div></li>
                        <li class="pointer"><div class="dropdown-item" href="javascript:void(0)" (click)="denialReason='Discontinued Product'">Discontinued Product</div></li>                
                        <li class="pointer"><div class="dropdown-item" href="javascript:void(0)" (click)="denialReason='Other'">Other</div></li>                
                    </ul>
                </div>
            </div>
        </div>
        <div class="form-group mb-3">
            <label>Denial Patient Facing Notes <i pTooltip="The patient will see this note in their prescription denied refund email." class="fa fa-info-circle"></i></label>
            <textarea class="form-control" placeholder="Your prescription is expired, as it ended on 12/07/2024. " [(ngModel)]="denialPatientFacingNote"></textarea>
        </div>
        <div class="form-group mb-3">
            <label>Denial Internal Note</label>
            <textarea class="form-control" placeholder="Your prescription is expired, as it ended on 12/07/2024. " [(ngModel)]="denialNote"></textarea>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-center">
    <button class="btn btn-cancel" style="width: 110px;" (click)="closeModal()">Cancel</button>
    <button class="btn btn-danger" style="width: 110px;" (click)="denyPrescription()">Deny</button>
</div>