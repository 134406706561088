import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders, HttpEventType } from '@angular/common/http';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  apiEndpoint:String = "";

  constructor(
    private http:HttpClient
  ) { 
    this.apiEndpoint = environment.NOTIFICATION_END_POINT;
  }

  getData(url,param)
  {
    return this.http.get<any>(this.apiEndpoint+url,{params:param});
  }

  postData(url,value,skip=false){
    // console.log(value);
    if(skip==false){
      Object.keys(value).forEach(key => {
        if(!value[key] || value[key] === undefined )
        delete value[key];
      });
    }
    // console.log(value);
    return this.http.post<any>(this.apiEndpoint+url,value);
  }
  
  putData(url,value)
  {
    return this.http.put<any>(this.apiEndpoint+url,value);
  }

  patchData(url,value)
  {
    return this.http.patch<any>(this.apiEndpoint+url,value);
  }

  deleteData(url,param)
  {
    return this.http.delete<any>(this.apiEndpoint+url,{params:param});
  }

  swal(title,text,type)
  {
    Swal.fire({
      title:title,
      text: text,
      icon: type,
      timer:2000
    });
  }
  
}
