<div class="detail-modal">
    <div class="detail-content">
        <div class="modal-header">
            <h3 class="modal-title">Developer Mode</h3>
        </div>
        <div class="p-2">
            <span class="badge mb-2 me-2 badge-primary">{{user?.name}}</span>
            <span class="badge mb-2 me-2 badge-orange">{{user?.type}}</span>
            <span class="badge mb-2 me-2 badge-blue">{{user?.enterpriseId?._id}}</span>
            <span class="badge mb-2 me-2 badge-success">{{user?.enterpriseId?.PMS?.system}}</span>
            <span class="badge mb-2 me-2 badge-warning">{{user?.system}}</span>
            <span class="badge mb-2 me-2 badge-danger">{{user?.systemId}}</span>
            
            <!-- <span class="badge bg-primary">{{user?.enterpriseId?.PMS?.system}}</span> -->
            <pre>{{user | json}}</pre>
        </div>
    </div>

    <div class="modal-footer text-center" style=" justify-content: center;">
        <button (click)="close()" class="btn btn-danger mb-3">Close</button>
    </div>
</div>