<div class="detail-modal inactive-account-modal">
    <div class="modal-header justify-content-center">
        <h3 class="modal-title text-center w-100 save-title">Inactive Account</h3>
    </div>
    <div class="detail-content p-4">
        <div class="text-center mb-3">
            <img src="assets/images/order-error-icon.svg"/>
        </div>
        <div class="alert text-center pb-0 mb-0 px-0 ">
            The office has canceled their subscription to the platform facilitating this interaction. Please call the office for more information or assistance.
        </div>
    </div>

    <div class="text-center pb-5">
        <a class="btn-primary btn mx-2 mt-1 mb-4" [style.background]="inactiveData?.brandColor" href="tel:{{inactiveData?.storePhone}}">Call Us</a>
    </div>
</div>
