export const environment = {
  production: false,
  API_END_POINT : "https://backend-api-dev.arrellio.com/v1/",
  // API_END_POINT : "https://backend-api-dev-vivek.arrellio.com/v1/",
  API_CATALOG_END_POINT : "https://catalog-api-dev.arrellio.com/v1/",
  API_PATIENT_REORDER_END_POINT:"https://reorder-api-dev.arrellio.com/v1",
  NOTIFICATION_END_POINT : "https://notification-api-dev.arrellio.com/v1/",
  REPORT_END_POINT : "https://report-api-dev.arrellio.com/v1/", 
  TICKET_END_POINT : "https://tickets-api-dev.arrellio.com/v1/", 
  SOCKET_END_POINT : "https://sockets-api-dev.arrellio.com/",
  REVOLUTION : "https://rev-api-dev.arrellio.com/",
  ZIPCODE_API_KEY : "js-6tQEG7H0MMZWp5q0WePvcqfKaYALtfuUwKxaDiB29gVgIAeVmAvAH2jlrJQ8YeGb",
  API_QR:"https://api.qr-code-generator.com/v1",
  STRIPEKEY: "pk_test_51OCq1bDMTWyisPjqknXFckbPFQ4MdobLcoc9yj2DuU5V85cgITRrOIYxtmYTW5qThRjCWMznTVk73qEV2gMBwaml00o8eePQ79",

};
