import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'title'
})
export class TitlePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    switch(value){
      case 'SUPERADMIN'         : value = 'Super Admin'; break;
      case 'ADMIN'              : value = 'Admin'; break;
      case 'STORE'              : value = 'Office'; break;
      case 'LAB'                : value = 'Lab'; break;
      case 'CALLCENTER'         : value = 'Support'; break;
      case 'SUPPORT'            : value = 'Support'; break;
      case 'active'             : value = 'Active'; break;
      case 'ACTIVE'             : value = 'Active'; break;
      case 'inactive'           : value = 'Inactive'; break;
      case 'INACTIVE'           : value = 'Inactive'; break;
      case 'DTO'                : value = 'Direct to Office (DTO)'; break;
      case 'DTP'                : value = 'Direct to Patient (DTP)'; break;
      case 'trialDrEvalNeeded'  : value = 'Dr. Eval Needed'; break;
      case 'okToDispense'       : value = 'Ok To Dispense'; break;
      case 'M'                  : value = 'Male'; break;
      case 'F'                  : value = 'Female'; break;
      case 'standard'           : value = 'Standard'; break;
      case 'nextDay'            : value = 'One Day'; break;
      case 'NextDay'            : value = 'One Day'; break;
      case 'secondDay'          : value = 'Two Day'; break;
      case 'SecondDay'          : value = 'Two Day'; break;
      case '0'                  : value = 'Active'; break;
      case '1'                  : value = 'Cancelled'; break; //Canceled by Office
      case '2'                  : value = 'Rescheduled'; break;
      case '3'                  : value = 'Completed'; break;
      case '4'                  : value = 'In Progress'; break;
      case '5'                  : value = 'No Show'; break;
      case '6'                  : value = 'Cancelled'; break; //Canceled by Patient
      case '99'                 : value = 'Manual'; break; //Canceled by Patient
      case 'NoIssues'           : value = 'No Issues'; break;
      case 'OfficeAssistance'   : value = 'Office Assistance'; break;
      case 'AdminReview'        : value = 'Admin Review'; break;
      case 'SupportReady'       : value = 'Support Ready'; break;
      case 'past_due'           : value = 'Past Due'; break;
      case 'in_transit'         : value = 'In Transit'; break;
      default : 
    }
    return value;
  }
}
