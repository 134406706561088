import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { promise } from 'selenium-webdriver';
import { SinglePatientComponent } from '../internal/admin/patients/single-patient/single-patient.component';
import { StatusReadyToProcessComponent } from '../internal/admin/status/status-ready-to-process/status-ready-to-process.component';
import { CreateTicketComponent } from '../internal/admin/tickets/create-ticket/create-ticket.component';
import { JobDetailsComponent } from '../shared/job-details/job-details.component';
import { TrackingDetailsComponent } from '../shared/tracking-details/tracking-details.component';
import { ApiService } from './api.service';
import { OutstandingBalanceComponent } from '../shared/outstanding-balance/outstanding-balance.component';
import { CheckBatchComponent } from '../shared/check-batch/check-batch.component';
import { Subject } from 'rxjs';
import { CheckInventoryComponent } from '../shared/check-inventory/check-inventory.component';
import { ViewImageComponent } from '../shared/view-image/view-image.component';
import { TrialBatchViewComponent } from '../shared/trial-batch-view/trial-batch-view.component';
import { ToastrService } from 'ngx-toastr';
import { UpdatePatientComponent } from '../shared/update-patient/update-patient.component';
import { InactiveAccountComponent } from '../shared/inactive-account/inactive-account.component';
import { DeveloperModeComponent } from '../shared/developer-mode/developer-mode.component';
import { WebstoreVerificationComponent } from '../internal/admin/webstore/webstore-verification/webstore-verification.component';
import { NoteViewComponent } from '../internal/admin/webstore/note-view/note-view.component';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  bsModalRef: BsModalRef;
  messages:any[]=[];
  statusTemplate;
  selectedStore;
  bsModalOutstandingBalanceRef:BsModalRef;
  bsModalRefTrialBatchViewComponent:BsModalRef;
  bsModalRefUpdatePatientComponent:BsModalRef;
  
  constructor(
    private api:ApiService,
    private modalService: BsModalService,
    private toastr:ToastrService
  ){
    this.getMessages();
  }

  async getMessages(){
    if(this.messages){
      return this.messages;
    }else{
      return await new Promise((resolve,reject)=>{
        let input:any={};
        input.skip = 0;
        input.limit = 100;
        input.status = 'active';
        console.log('11111')
        this.api.getData('settings/message',input).subscribe(r=>{
          this.messages = r.listing;
          resolve(this.messages);
        },err=>{
          reject([]);
        });
      });
    }
  }

  openModalPatient(jobId) {
    let initialState: any = {};
    initialState.class = 'modal-xl modal-dialog-centered';
    initialState.initialState={};
    initialState.initialState.jobId = jobId;
    this.bsModalRef = this.modalService.show(SinglePatientComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openModalWebstoreVerification(item){
    let initialState: any = {};
    initialState.class = 'modal-xl modal-dialog-centered webstore-verification-modal';
    initialState.initialState={};
    initialState.initialState.item = item;
    initialState.initialState.onClose = new Subject();
    this.bsModalRef = this.modalService.show(WebstoreVerificationComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
    return this.bsModalRef.content.onClose;
  }

  openModalNote(item){
    let initialState: any = {};
    initialState.class = 'modal-md denial-popup-modal modal-dialog modal-dialog-centered notes-window-modal';
    initialState.initialState={};
    initialState.initialState.item = item;
    this.bsModalRef = this.modalService.show(NoteViewComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openModalTicket(selectedJob) {
    let initialState: any = {};
    initialState.class = 'modal-xl modal-dialog-centered';
    initialState.backdrop = "static";
    initialState.initialState={};
    initialState.initialState.selectedJob = selectedJob;
    this.bsModalRef = this.modalService.show(CreateTicketComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openModalStatus(selectedJob) {
    let initialState: any = {};
    initialState.class = 'modal-xl modal-dialog-centered';
    initialState.backdrop = "static";
    initialState.initialState={};
    initialState.initialState.selectedJob = selectedJob;
    initialState.initialState.statusTemplate = this.statusTemplate;
    this.bsModalRef = this.modalService.show(StatusReadyToProcessComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openModalJobDetails(selectedJob) {
    let initialState: any = {};
    initialState.class = 'modal-lg modal-dialog-centered';
    // initialState.backdrop = "static";
    initialState.initialState={};
    initialState.initialState.selectedJob = selectedJob;
    initialState.initialState.jobData = this.jobData;
    this.bsModalRef = this.modalService.show(JobDetailsComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }


  openModalTrackingDetails(selectedJob) {
    let initialState: any = {};
    initialState.class = 'modal-lg modal-dialog-centered';
    // initialState.backdrop = "static";
    initialState.initialState={};
    initialState.initialState.selectedJob = selectedJob;
    this.bsModalRef = this.modalService.show(TrackingDetailsComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openModalViewImage(data) {
    let initialState: any = {};
    initialState.class = 'modal-lg modal-dialog-centered';
    // initialState.backdrop = "static";
    initialState.initialState={};
    initialState.initialState.title = data.title || 'Image';
    initialState.initialState.url = data.url;
    this.bsModalRef = this.modalService.show(ViewImageComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openModalInactiveAccount(data) {
    let initialState: any = {};
    initialState.class = 'modal-dialog-centered overlay-dark';
    initialState.initialState={};
    initialState.backdrop = "static";
    initialState.initialState.inactiveData = data;
    this.bsModalRef = this.modalService.show(InactiveAccountComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openDeveloperMode() {
    let initialState: any = {};
    initialState.class = 'modal-lg modal-dialog-centered';
    initialState.initialState={};
    this.bsModalRef = this.modalService.show(DeveloperModeComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  openTrialBatchView(data) {
      let initialState: any = {};
      initialState.class = 'modal-xl modal-dialog-centered';
      initialState.backdrop = "static";
      initialState.initialState={};
      initialState.initialState.onClose = new Subject();
      initialState.initialState.jobs = data.jobs;
      initialState.initialState.orderDate = data.orderDate;
      initialState.initialState.actions = data.actions;
      initialState.initialState.storeId = data.storeId;
      this.bsModalRefTrialBatchViewComponent = this.modalService.show(TrialBatchViewComponent, initialState);
      this.bsModalRefTrialBatchViewComponent.content.closeBtnName = 'Close';
      return this.bsModalRefTrialBatchViewComponent.content.onClose;
  }

  async checkOutstandingBalance(selectedJob) {
    return new Promise((resolve,reject)=>{
      // selectedJob.Soft_CL_Payment_Amount = '20'; // testing only
      if(selectedJob.jobSubType == 'DTO' && 
      (!selectedJob.Soft_CL_Payment_Amount || parseFloat(selectedJob.Soft_CL_Sales_Amount) > parseFloat(selectedJob.Soft_CL_Payment_Amount))){
        let initialState: any = {};
        initialState.class = 'modal-dialog-centered common-modal';
        // initialState.backdrop = "static";
        initialState.initialState={};
        initialState.initialState.onClose = new Subject();
        initialState.initialState.selectedJob = selectedJob;
        this.bsModalOutstandingBalanceRef = this.modalService.show(OutstandingBalanceComponent, initialState);
        this.bsModalOutstandingBalanceRef.content.closeBtnName = 'Close';
        this.bsModalOutstandingBalanceRef.content.onClose.subscribe(res => {
          if(res){
            resolve(true);
          }
          reject(false);
        });
      }else{
        resolve(true);
      }
    });
  }
  

  async checkInventory() {
    return new Promise((resolve,reject)=>{
      let input:any={};
      // checking for inventory DTO jobs
      this.api.getData(`countInventory`,input).subscribe(r=>{
          if(r){
            // inventory has DTO jobs
            let initialState: any = {};
            initialState.class = 'modal-dialog-centered common-modal';
            initialState.initialState={};
            initialState.initialState.onClose = new Subject();
            initialState.initialState.count = r;
            this.bsModalOutstandingBalanceRef = this.modalService.show(CheckInventoryComponent, initialState);
            this.bsModalOutstandingBalanceRef.content.closeBtnName = 'Close';
            this.bsModalOutstandingBalanceRef.content.onClose.subscribe(res => {
              if(res){
                // proceed anyway
                resolve(true);
              }
              reject(false);
            });
          }else{
            // No inventory DTO jobs found // proceed
            resolve(true);
          }
      },error=>{
        reject(false);
      });
    });
  }

  async checkBatch(batchTotal, threshold) {
    return new Promise((resolve,reject)=>{
      if(batchTotal < threshold){
        // Less then threshold ask to proceed
        let initialState: any = {};
        initialState.class = 'modal-dialog-centered common-modal';
        initialState.initialState={};
        initialState.initialState.onClose = new Subject();
        initialState.initialState.batchTotal = batchTotal;
        initialState.initialState.threshold  = threshold;
        this.bsModalOutstandingBalanceRef = this.modalService.show(CheckBatchComponent, initialState);
        this.bsModalOutstandingBalanceRef.content.closeBtnName = 'Close';
        this.bsModalOutstandingBalanceRef.content.onClose.subscribe(res => {
          if(res){
            // proceed anyway
            resolve(true);
          }
          reject(false);
        });
      }else{
        // Greater then threshold // proceed
        resolve(true);
      }

    });
  }

  autoTrial(job,selectedPatient){
    return new Promise((resolve,reject):any=>{
      if(job.businessType == 'Trials'){
        resolve({status:false,message:'Trial Order'});
      }
      let cart:any={};
      let input:any={};
      input.patientId = selectedPatient._id;
      this.api.getData(`trial/patient/upc/${job._id}`,input).subscribe(res=>{
        if(res['data']){
          cart = res['data'];
          let input:any={};
          input.upc =  cart.lineItems.map(e=>{return e.upc});
          this.api.putData(`trial/cart/patient/confirmOrder/${cart._id}`,input).subscribe(res=>{
            resolve({status:true, message:'Trial Order Success'});
          },error=>{
            resolve({status:false,message:error.error.message});
          });
        }else{
          resolve({status:false,message:res?.message || 'No data found'});
        }
        resolve("no")
      },error=>{
        resolve({status:false,message:error.error.message});
        return error;
      });
    });
  }

  sum(a,b):number{
    let aa = 0;
    let bb = 0;
    if(typeof a == 'string'){
      aa = parseFloat(a.replace(/[^0-9.]+/g, '')) || 0;
    }else{
      aa = a;
    }
    if(typeof b == 'string'){
      bb = parseFloat(b.replace(/[^0-9.]+/g, '')) || 0;
    }else{
      bb = b;
    }
    return aa+bb;
  }

  lower(text){
    if(!text){
      return false;
    }
    return text.toLowerCase();
  }

  jobData = [
      'Patient_Patient_ID',
      'Patient_First_Name',
      'Patient_Last_Name',
      'Patient_Gender',
      'Patient_Date_of_Birth',
      'Patient_Address_1',
      'Patient_Address_2',
      'Patient_Zip_Code',
      'Patient_City',
      'Patient_State',
      'Patient_Country',
      'Patient_Phone',
      'Patient_Email_Address',
      'Eyeglass_Order_Date',
      'Eyeglass_Order_Store_Number',
      'Eyeglass_Order_Number',
      'Eyeglass_Original_Number_For_Remake',
      'Eyeglass_Acuity_Type',
      'Eyeglass_Right_Type',
      'Eyeglass_Right_Material',
      'Eyeglass_Right_Style',
      'Eyeglass_Right_Color',
      'Eyeglass_Right_Coating',
      'Eyeglass_Right_Sphere',
      'Eyeglass_Right_Cylinder',
      'Eyeglass_Right_Axis',
      'Eyeglass_Right_Thickness',
      'Eyeglass_Right_Prism1',
      'Eyeglass_Right_Prism1_Direction',
      'Eyeglass_Right_Prism2',
      'Eyeglass_Right_Prism2_Direction',
      'Eyeglass_Right_Add1',
      'Eyeglass_Right_Add2',
      'Eyeglass_Right_Segment_Height',
      'Eyeglass_Right_OC_Height',
      'Eyeglass_Right_Far_PD',
      'Eyeglass_Right_Near_PD',
      'Eyeglass_Right_Base_Curve',
      'Eyeglass_Right_Slab_Off',
      'Eyeglass_Right_Balanced',
      'Eyeglass_Left_Type',
      'Eyeglass_Left_Material',
      'Eyeglass_Left_Style',
      'Eyeglass_Left_Color',
      'Eyeglass_Left_Coating',
      'Eyeglass_Left_Sphere',
      'Eyeglass_Left_Cylinder',
      'Eyeglass_Left_Axis',
      'Eyeglass_Left_Thickness',
      'Eyeglass_Left_Prism1',
      'Eyeglass_Left_Prism1_Direction',
      'Eyeglass_Left_Prism2',
      'Eyeglass_Left_Prism2_Direction',
      'Eyeglass_Left_Add1',
      'Eyeglass_Left_Add2',
      'Eyeglass_Left_Segment_Height',
      'Eyeglass_Left_OC_Height',
      'Eyeglass_Left_Far_PD',
      'Eyeglass_Left_Near_PD',
      'Eyeglass_Left_Base_Curve',
      'Eyeglass_Left_Slab_Off',
      'Eyeglass_Left_Balanced',
      'Eyeglass_Near_Only',
      'Eyeglass_Uncut',
      'Eyeglass_Vertex',
      'Eyeglass_Pantoscopic_Tilt',
      'Eyeglass_Frame_Wrap',
      'Eyeglass_Frame_Name',
      'Eyeglass_Frame_Item_Number',
      'Eyeglass_Frame_Collection',
      'Eyeglass_Frame_UPC',
      'Eyeglass_Frame_Color',
      'Eyeglass_Frame_Type',
      'Eyeglass_Frame_Eye',
      'Eyeglass_Frame_Bridge',
      'Eyeglass_Frame_Temple',
      'Eyeglass_Frame_A',
      'Eyeglass_Frame_B',
      'Eyeglass_Frame_ED',
      'Eyeglass_Frame_DBL',
      'Eyeglass_Extra_Apply',
      'Eyeglass_Extra_Tint_Type',
      'Eyeglass_Extra_Tint_Color',
      'Eyeglass_Extra_Tint_Color_Comment',
      'Eyeglass_Extra_Edge',
      'Eyeglass_Extra_Coating_1',
      'Eyeglass_Extra_Coating_2',
      'Eyeglass_Extra_Coating_3',
      'Eyeglass_Extra_Coating_4',
      'Eyeglass_Extra_Backside_A_R',
      'Eyeglass_Extra_Misc_1',
      'Eyeglass_Extra_Misc_2',
      'Eyeglass_Extra_Misc_3',
      'Eyeglass_Extra_Misc_4',
      'Eyeglass_Remake_Reason',
      'Eyeglass_Order_Type',
      'Eyeglass_Consultant',
      'Eyeglass_Ship_To',
      'Eyeglass_Lab',
      'Eyeglass_Lab_Instructions',
      'Eyeglass_Lab_To_Make_Frame',
      'Eyeglass_Lab_To_Make_Right_Lens',
      'Eyeglass_Lab_To_Make_Left_Lens',
      'Eyeglass_Lab_To_Make_Extras',
      'Eyeglass_Dispensing_Notes',
      'Soft_CL_Order_Date',
      'Soft_CL_Order_Store_Number',
      'Soft_CL_Order_Number',
      'Soft_CL_Acuity_Type',
      'Soft_CL_Lens_Right_Manufacturer',
      'Soft_CL_Lens_Right_Style',
      'Soft_CL_Lens_Right_Base',
      'Soft_CL_Lens_Right_Diameter',
      'Soft_CL_Lens_Right_Color',
      'Soft_CL_Lens_Right_Sphere',
      'Soft_CL_Lens_Right_Cylinder',
      'Soft_CL_Lens_Right_Axis',
      'Soft_CL_Lens_Right_Add',
      'Soft_CL_Lens_Right_Quantity',
      'Soft_CL_Lens_Left_Manufacturer',
      'Soft_CL_Lens_Left_Style',
      'Soft_CL_Lens_Left_Base',
      'Soft_CL_Lens_Left_Diameter',
      'Soft_CL_Lens_Left_Color',
      'Soft_CL_Lens_Left_Sphere',
      'Soft_CL_Lens_Left_Cylinder',
      'Soft_CL_Lens_Left_Axis',
      'Soft_CL_Lens_Left_Add',
      'Soft_CL_Lens_Left_Quantity',
      'Soft_CL_Consultant',
      'Soft_CL_Ship_To',
      'Soft_CL_Supply_Source',
      'Soft_CL_Instructions',
      'Soft_CL_Parts_to_Supply_Right_Lens',
      'Soft_CL_Parts_to_Supply_Left_Lens',
      'Soft_CL_Dispensing_Notes',
      'Hard_CL_Order_Date',
      'Hard_CL_Order_Number',
      'Hard_CL_Acuity_Type',
      'Hard_CL_Lens_Right_Manufacturer',
      'Hard_CL_Lens_Right_Style',
      'Hard_CL_Lens_Right_Base',
      'Hard_CL_Lens_Right_Diameter',
      'Hard_CL_Lens_Right_Color',
      'Hard_CL_Lens_Right_Sphere',
      'Hard_CL_Lens_Right_Cylinder',
      'Hard_CL_Lens_Right_Axis',
      'Hard_CL_Lens_Right_Add',
      'Hard_CL_Lens_Right_Quantity',
      'Hard_CL_Lens_Left_Manufacturer',
      'Hard_CL_Lens_Left_Style',
      'Hard_CL_Lens_Left_Base',
      'Hard_CL_Lens_Left_Diameter',
      'Hard_CL_Lens_Left_Color',
      'Hard_CL_Lens_Left_Sphere',
      'Hard_CL_Lens_Left_Cylinder',
      'Hard_CL_Lens_Left_Axis',
      'Hard_CL_Lens_Left_Add',
      'Hard_CL_Lens_Left_Quantity',
      'Hard_CL_Consultant',
      'Hard_CL_Ship_To',
      'Hard_CL_Supply_Source',
      'Hard_CL_Parts_Instructions',
      'Hard_CL_Parts_to_Supply_Right_Lens',
      'Hard_CL_Parts_to_Supply_Left_Lens',
      'Hard_CL_Dispensing_Notes'
  ]
    
}
