import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-upload-prescription',
  templateUrl: './upload-prescription.component.html',
  styleUrls: ['./upload-prescription.component.css']
})
export class UploadPrescriptionComponent implements OnInit {
  patientDetails:any;
  prescriptionUrl:any;
  user:any;
  errorMsg: string;
  prescriptionName:string;
  brandColor:string=''
  ePrescriptionName:string=''
  constructor(
    private api: ApiService,
    private router: Router,
    private bsModalRef: BsModalRef
  ){
    
  }

  ngOnInit(){
    console.log("this.patientDetails in upload prescription",this.patientDetails)
    this.user=this.api.user;
    console.log("this.ePrescriptionName",this.ePrescriptionName)
    console.log("this.prescriptionUrl",this.prescriptionUrl)
 }
  onAttachFile(event) {
    this.prescriptionName =this.user.Patient_Last_Name;
    const file = event.target.files[0];
    const MAX_PDF_PAGES = 10;
    const MAX_FILE_SIZE = 4.5 * 1024 * 1024; // 4.5 MB
    const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];
  
    // Validate file type
    if (!ALLOWED_TYPES.includes(file.type)) {
      this.errorMsg = 'Only JPG, PNG, and PDF files are allowed.';
      return;
    }
  
    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      this.errorMsg = 'File size should not exceed 4.5 MB.';
      return;
    }
  
    // Handle PDF-specific validation
    if (file.type === 'application/pdf') {
      const fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const pageMatches = (e.target.result as string).match(/\/Type\s*\/Page\b/g);
        if ((pageMatches?.length || 0) > MAX_PDF_PAGES) {
          this.errorMsg = 'PDF should not exceed 10 pages.';
          return;
        }
        this.uploadFile(file); // Proceed with upload after validation
      };
      fileReader.readAsText(file); // Basic text-based page count estimation
      return; // Prevent proceeding until PDF validation is complete
    }
  
    this.uploadFile(file); // Upload other file types directly
  }
  
  getPrescriptionName() {
    const today = new Date();
    // Store original name first
    const originalName = this.patientDetails.patientId.Patient_Last_Name;
    // Remove file extension and create new formatted name
    const nameWithoutExtension = originalName.split('.')[0];
    let formattedName = `${nameWithoutExtension}_Rx_Upload_${today.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit'
    }).replace(/\//g, '_')}`;
    this.ePrescriptionName=formattedName;
    return formattedName;
    

  }
  uploadFile(file) {
    this.errorMsg = '';
    const formData = new FormData();
    formData.append('image', file);
  
    this.api.postData('common/uploadImage', formData).subscribe(
      res => {
        console.log('File uploaded:', res);
        this.prescriptionUrl = res.original;
        this.getPrescriptionName();
      },
      () => {
        this.errorMsg = 'Error uploading file. Please try again.';
      }
    );
  }
  onRemovePrescription(){
    this.prescriptionUrl=null;
  }
  onUploadPrescription(){
    let input:any={};
     console.log("this.prescriptionUrl",this.prescriptionUrl)
    if(!this.prescriptionUrl || this.prescriptionUrl==undefined){
      this.errorMsg="Please upload a prescription";
      return;
    }
    input.ePrescription=this.prescriptionUrl;
    input.ePrescriptionName=this.ePrescriptionName;
    console.log("this.prescriptionUrl",this.prescriptionUrl)
    this.api.putData(`ecommerce/patient/${this.api.user._id}` , input).subscribe(res => {
      console.log("prescription upload", res)
      this.bsModalRef.hide();
    }, (error) => {
    });
  }

}
