<div class="modal-header justify-content-center">
    <h2 class="modal-title">Webstore Order Verification</h2>
</div>
<div class="modal-body p-lg-4">
    <div class="dashboard-form">
        <div id="accordion">
            <div class="card">
              <div class="card-header" [ngStyle]="{'background-color': confirmPatientInfoStepActive ? '#DAFDC0' : ''}">
                <a class="btn d-flex align-items-center justify-content-between" data-bs-toggle="collapse" href="#collapseOne" (click)="resetConfirmPatientInfo()">
                    <span>1. Patient Information</span>
                    <button *ngIf="patientDetails?.eStatus=='existing'" class="btn btn-info">Existing Patient - No Patient Confirmation Needed</button>
                    <i *ngIf="patientDetails?.eStatus =='new'" class="fa-solid fa-pen-to-square"></i>
                </a>
              </div>
              <div id="collapseOne" class="collapse" data-bs-parent="#accordion" [class.active]="confirmPatientInfo==false">
                <div class="card-body px-lg-4">
                  <p class="mb-4 verification-text" *ngIf="patientDetails?.eStatus=='existing'">This was an existing patient purchase, and thus, no patient information needed to be confirmed.</p>
                  <p class="mb-4 verification-text" *ngIf="patientDetails?.eStatus=='new'">This patient self-identified as a new patient when purchasing their contact lenses. This may be due to patient error or that their patient record predates when you went live with Arrellio. To avoid duplicate charts, we need you to confirm the status of this patient as new or existing.</p>
                  <div class="row">
                    <div class="col-md-6 col-xl-3">
                        <div class="form-group mb-4">
                            <label for="patientFirstName">First Name</label>
                            <input type="text" class="form-control" id="patientFirstName" [ngModel]="patientDetails?.Patient_First_Name" placeholder="Andrew" disabled>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="form-group mb-4">
                            <label for="patientLastName">Last Name</label>
                            <input type="text" class="form-control" id="patientLastName" [ngModel]="patientDetails?.Patient_Last_Name" placeholder="Smith" disabled>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="form-group mb-4">
                            <label for="patientDOB">Date of Birth</label>
                            <input type="text" class="form-control" id="patientDOB" [ngModel]="patientDetails?.Patient_Date_of_Birth" placeholder="01/01/1990" disabled>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="form-group mb-4">
                            <label for="patientPhone">Sex</label>
                            <input type="text" class="form-control" id="patientPhone" [ngModel]="patientDetails?.Patient_Gender" placeholder="Male or Female" disabled>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="form-group mb-4">
                            <label for="patientPhone">Phone Number</label>
                            <input type="text" class="form-control" id="patientPhone" [ngModel]="patientDetails?.Patient_Primary_Phone" placeholder="(123) 456-7890" disabled>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="form-group mb-4">
                            <label for="patientEmail">Email Address</label>
                            <input type="text" class="form-control" id="patientEmail" [ngModel]="patientDetails?.Patient_Email_Address" placeholder="andrew@smith.com" disabled>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="form-group mb-4">
                            <label for="patientEmail">Patient Status </label>
                            <div class="d-flex align-items-center gap-2">
                                <button class="btn w-50"  [class.disabled]="item.webstoreTicketStatus=='Completed' || item.webstoreTicketStatus=='Denied' || item.webstoreTicketStatus=='Cancelled'" [class.btn-primary]="eStatusByOffice=='new'" [class.btn-primary-outline]="eStatusByOffice=='existing'" (click)="setOfficeEditedPatientStatus('new')">New</button>
                                <button class="btn w-50" [class.disabled]="item.webstoreTicketStatus=='Completed' || item.webstoreTicketStatus=='Denied' || item.webstoreTicketStatus=='Cancelled'" [class.btn-primary]="eStatusByOffice=='existing'" [class.btn-primary-outline]="eStatusByOffice=='new'" (click)="setOfficeEditedPatientStatus('existing')">Existing</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-3">
                        <div class="form-group mb-4">
                            <label for="patientEmail">Patient ID <i class="fa fa-info-circle" aria-hidden="true" pTooltip="Please create the new patient’s chart and input their patient ID here."></i>
                            </label>
                            <input type="text" class="form-control" id="patientEmail" [(ngModel)]="patientDetails.Patient_Point_of_Sale_Patient_ID" placeholder="">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group text-end">
                            <button class="btn btn-success" [class.disabled]="disablePatientInfoConfirm()" (click)="onConfirmPatientInfo()">Confirm</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" [ngStyle]="{'background-color': confirmPrescriptionInfoStepActive ? '#DAFDC0' : ''}">
                <a class="collapsed btn d-flex align-items-center justify-content-between" data-bs-toggle="collapse" href="#collapseTwo" (click)="resetConfirmPrescriptionInfo()">
                <span>2. Prescription Verification</span>
                <button *ngIf="patientDetails?.eStatus=='existing'" class="btn btn-info">Existing Patient - No Rx Verification Needed</button>
                <button *ngIf="item.webstoreTicketStatus=='Denied'" class="btn btn-danger">Rx Denied - {{item?.denialReason}}</button>
                <i *ngIf="patientDetails?.eStatus =='new'" class="fa-solid fa-pen-to-square"></i>
              </a>
              </div>
              <div id="collapseTwo" class="collapse" data-bs-parent="#accordion" [class.active]="confirmPrescriptionInfo==false">
                <div class="card-body px-lg-4">
                  <p class="mb-4 verification-text" *ngIf="patientDetails?.eStatus=='existing'">This was an existing patient purchase, and thus, no prescription verification was needed.</p>
                  <p class="mb-4 verification-text" *ngIf="patientDetails?.eStatus=='new'">Given the patient self-identified as a new patient, we need you to verify the patient's provided prescription. All parameters and the patient's uploaded document are shown below. If the prescription is expired, you can deny it and we'll refund and notify the patient accordingly. If the prescription is valid, you will confirm it and move to the next step of financial data verification.</p>
                  <div class="inline-filters">
                    <div class="row">
                        <div class="col-md-6 col-xl-3 mb-xl-0 mb-3">
                            <div class="form-group d-flex align-items-center gap-2">
                                <label for="startDate">Start Date</label>
                                <input type="text" class="form-control" id="startDate" [ngModel]="prescription.Soft_CL_Associated_Rx_Start_Date | date:'MM/dd/yyyy'" placeholder="01/01/2025">
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 mb-xl-0 mb-3">
                            <div class="form-group d-flex align-items-center gap-2">
                                <label for="endDate">End Date</label>
                                <input type="text" class="form-control" id="endDate" [ngModel]="prescription.Soft_CL_Associated_Rx_Expiration_Date | date:'MM/dd/yyyy'" placeholder="01/01/2025">
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 mb-xl-0 mb-3">
                            <div class="form-group d-flex align-items-center gap-2">
                                <label for="doctorName">Doctor Name:</label>
                                <input type="text" class="form-control" id="doctorName" [ngModel]="prescription.Soft_CL_Associated_Rx_Doctor" placeholder="Active">
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 mb-xl-0 mb-3">
                            <div class="form-group d-flex align-items-center gap-2">
                                <label for="officeName">Office Name:</label>
                                <input type="text" class="form-control" id="officeName" [ngModel]="prescription.Soft_CL_Associated_Rx_Office_Name" placeholder="Active">
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-6 lock-eye" [class.lock-eye]="lockRightEye">
                        <div class="lock-eye-icon" pTooltip="To add lenses to the right eye in this order, click here." *ngIf="lockRightEye" style="cursor: pointer;" (click)="lockRightEye = false">
                            <i class="fa-solid fa-lock"></i>
                        </div>
                        <div class="eye-title d-flex align-items-center justify-content-between my-3">
                            <span class="eye-title-text">Right Eye (OD)</span>
                            <span class="eye-flag" style="cursor: pointer;" (click)="copyRightToLeft()" [class.disabled]="lockLeftEye">COPY TO LEFT EYE (OS)</span>
                        </div>
                        <div class="row">

                            <div class="col-md-12">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Manufacturer</label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown"
                                        [disabled]="patientDetails?.eStatus=='existing'"
                                        >
                                           {{eyeDataOffice?.right?.manufacturerName}}
                                            <del *ngIf="eyeDataPatient?.right?.manufacturerName != eyeDataOffice?.right?.manufacturerName" class="del-text">{{eyeDataPatient?.right?.manufacturerName}}</del>
                                           <img *ngIf="eyeDataOffice?.right?.manufacturers?.length>1 && patientDetails?.eStatus !=='existing'" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                          <li *ngFor="let item of eyeDataOffice?.right?.manufacturers"><a
                                            (click)="setEyeType('right');selectManufacturer(item,true,'right')"
                                            class="dropdown-item">{{item?.arrellioManufacturerName}}</a></li>
                                        </ul>
                                      </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Brand <i (click)="getSeries('right')" *ngIf="patientDetails?.eStatus !=='existing'" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" 
                                        data-bs-toggle="dropdown"
                                        [disabled]="eyeDataOffice?.right?.styles?.length==0 || eyeDataOffice?.right?.styles?.length==1 ||!eyeDataOffice?.right?.styles"
                                        >
                                           {{eyeDataOffice?.right?.style}}
                                           <del *ngIf="eyeDataPatient?.right?.style != eyeDataOffice?.right?.style" class="del-text">{{eyeDataPatient?.right?.style}}</del>
                                           <img *ngIf="eyeDataOffice?.right?.styles?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                          <li *ngFor="let item of eyeDataOffice?.right?.styles"><a
                                            (click)="setEyeType('right');selectSeries(item,'right')"
                                            class="dropdown-item">{{item?.arrellioContactLensSeriesName}}</a></li>
                                        </ul>
                                      </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Base Curve <i (click)="getBaseCurve('right')" *ngIf="eyeDataOffice?.rightPrescriptionMultiField?.baseCurves" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.right?.baseCurves?.length==0 || eyeDataOffice?.right?.baseCurves?.length==1 ||!eyeDataOffice?.right?.baseCurves"
                                        >
                                          {{eyeDataOffice?.right?.baseCurveName}}
                                          <del *ngIf="eyeDataPatient?.right?.baseCurveName != eyeDataOffice?.right?.baseCurveName" class="del-text">{{eyeDataPatient?.right?.baseCurveName}}</del>
                                          <img *ngIf="eyeDataOffice?.right?.baseCurves?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.right?.baseCurves"><a
                                                (click)="setEyeType('right');selectBaseCurveName(item,true,'right')"
                                                class="dropdown-item">{{item?.baseCurveName}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Diameter <i (click)="getDiameter('right')" *ngIf="eyeDataOffice?.rightPrescriptionMultiField?.diameters" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.right?.diameters?.length==0 || eyeDataOffice?.right?.diameters?.length==1 ||!eyeDataOffice?.right?.diameters"
                                        >
                                          {{eyeDataOffice?.right?.diameter}}
                                          <del *ngIf="eyeDataPatient?.right?.diameter != eyeDataOffice?.right?.diameter" class="del-text">{{eyeDataPatient?.right?.diameter}}</del>
                                          <img *ngIf="eyeDataOffice?.right?.diameters?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.right?.diameters"><a
                                                (click)="setEyeType('right');selectDiameter(item,true,'right')"
                                                class="dropdown-item">{{item?.diameter}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Sphere <i (click)="getSpheres('right')" *ngIf="eyeDataOffice?.rightPrescriptionMultiField?.spheres" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.right?.spheres?.length==0 || eyeDataOffice?.right?.spheres?.length==1 ||!eyeDataOffice?.right?.spheres"
                                        >
                                          {{eyeDataOffice?.right?.sphere}}
                                          <del *ngIf="eyeDataPatient?.right?.sphere != eyeDataOffice?.right?.sphere" class="del-text">{{eyeDataPatient?.right?.sphere}}</del>
                                          <img *ngIf="eyeDataOffice?.right?.spheres?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.right?.spheres"><a
                                                (click)="setEyeType('right');selectSphere(item,true,'right')"
                                                class="dropdown-item">{{item?.sphere}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Cylinder <i (click)="getCylinders('right')" *ngIf="eyeDataOffice?.rightPrescriptionMultiField?.cylinders" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.right?.cylinders?.length==0 || eyeDataOffice?.right?.cylinders?.length==1 ||!eyeDataOffice?.right?.cylinders"
                                        >
                                          {{eyeDataOffice?.right?.cylinder}}
                                          <del *ngIf="eyeDataPatient?.right?.cylinder != eyeDataOffice?.right?.cylinder" class="del-text">{{eyeDataPatient?.right?.cylinder}}</del>
                                          <img *ngIf="eyeDataOffice?.right?.cylinders?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.right?.cylinders"><a
                                                (click)="setEyeType('right');selectCylinder(item,true,'right')"
                                                class="dropdown-item">{{item?.cylinder}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Axis <i (click)="getAxis('right')" *ngIf="eyeDataOffice?.rightPrescriptionMultiField?.axes" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.right?.axis?.length==0 || eyeDataOffice?.right?.axis?.length==1 ||!eyeDataOffice?.right?.axis"
                                        >
                                          {{eyeDataOffice?.right?.axisName}}
                                          <del *ngIf="eyeDataPatient?.right?.axisName != eyeDataOffice?.right?.axisName" class="del-text">{{eyeDataPatient?.right?.axisName}}</del>
                                          <img *ngIf="eyeDataOffice?.right?.axis?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.right?.axis"><a
                                                (click)="setEyeType('right');selectAxis(item,true,'right')"
                                                class="dropdown-item">{{item?.axis}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Add <i (click)="getAdds('right')" *ngIf="eyeDataOffice?.rightPrescriptionMultiField?.adds" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.right?.adds?.length==0 || eyeDataOffice?.right?.adds?.length==1 ||!eyeDataOffice?.right?.adds"
                                        >
                                          {{eyeDataOffice?.right?.add}}
                                          <del *ngIf="eyeDataPatient?.right?.add != eyeDataOffice?.right?.add" class="del-text">{{eyeDataPatient?.right?.add}}</del>
                                          <img *ngIf="eyeDataOffice?.right?.adds?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.right?.adds"><a
                                                (click)="setEyeType('right');selectAdd(item,true,'right')"
                                                class="dropdown-item">{{item?.add}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Color <i (click)="getColors('right')" *ngIf="eyeDataOffice?.rightPrescriptionMultiField?.colors" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.right?.colors?.length==0 || eyeDataOffice?.right?.colors?.length==1 ||!eyeDataOffice?.right?.colors"
                                        >
                                          {{eyeDataOffice?.right?.color}}
                                          <del *ngIf="eyeDataPatient?.right?.color != eyeDataOffice?.right?.color" class="del-text">{{eyeDataPatient?.right?.color}}</del>
                                          <img *ngIf="eyeDataOffice?.right?.colors?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.right?.colors"><a
                                                (click)="setEyeType('right');selectColor(item,true,'right')"
                                                class="dropdown-item">{{item?.color}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Lens Count <i (click)="getLensCounts('right')" *ngIf="eyeDataOffice?.rightPrescriptionMultiField?.lensCounts" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.right?.quantities?.length==0 || eyeDataOffice?.right?.quantities?.length==1 ||!eyeDataOffice?.right?.quantities"
                                        >
                                          {{eyeDataOffice?.right?.quantity}}
                                          <del *ngIf="eyeDataPatient?.right?.quantity != eyeDataOffice?.right?.quantity" class="del-text">{{eyeDataPatient?.right?.quantity}}</del>
                                          <img *ngIf="eyeDataOffice?.right?.quantities?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.right?.lensCounts"><a
                                                (click)="setEyeType('right');selectLensCount(item,true,'right')"
                                                class="dropdown-item">{{item?.lensCount}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6" [class.lock-eye]="lockLeftEye">
                        <div class="lock-eye-icon" pTooltip="To add lenses to the left eye in this order, click here." *ngIf="lockLeftEye" style="cursor: pointer;" (click)="lockLeftEye = false">
                            <i class="fa-solid fa-lock"></i>
                        </div>
                        <div class="eye-title d-flex align-items-center justify-content-between my-3">
                            <span class="eye-title-text">Left Eye (OS)</span>
                            <span class="eye-flag" style="cursor: pointer;" (click)="copyLeftToRight()" [class.disabled]="lockRightEye">COPY TO RIGHT EYE (OS)</span>
                        </div>
                        <div class="row">

                            <div class="col-md-12">
                                <div class="form-group mb-4">
                                    <label for="brand">Manufacturer</label>
                                    <div class="dropdown">
                                            <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown"
                                            [disabled]="eyeDataOffice?.left?.manufacturers?.length==0 || eyeDataOffice?.left?.manufacturers?.length==1 ||!eyeDataOffice?.left?.manufacturers || patientDetails?.eStatus=='existing'"
                                        >
                                           {{eyeDataOffice?.left?.manufacturerName}}
                                           <del *ngIf="eyeDataPatient?.left?.manufacturerName != eyeDataOffice?.left?.manufacturerName" class="del-text">{{eyeDataPatient?.left?.manufacturerName}}</del>
                                           <img *ngIf="eyeDataOffice?.left?.manufacturers?.length>1 && patientDetails?.eStatus !=='existing'" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                          <li *ngFor="let item of eyeDataOffice?.left?.manufacturers"><a
                                            (click)="setEyeType('left');selectManufacturer(item,true,'left')"
                                            class="dropdown-item">{{item?.arrellioManufacturerName}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Brand<i (click)="getSeries('left')" *ngIf="patientDetails?.eStatus !=='existing'" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown"
                                        [disabled]="eyeDataOffice?.left?.styles?.length==0 || eyeDataOffice?.left?.styles?.length==1 ||!eyeDataOffice?.left?.styles"
                                        >
                                           {{eyeDataOffice?.left?.style}}
                                           <del *ngIf="eyeDataPatient?.left?.style != eyeDataOffice?.left?.style" class="del-text">{{eyeDataPatient?.left?.style}}</del>
                                           <img *ngIf="eyeDataOffice?.left?.styles?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100" >
                                          <li *ngFor="let item of eyeDataOffice?.left?.styles"><a
                                            (click)="setEyeType('left');selectSeries(item,'left')"
                                            class="dropdown-item">{{item?.arrellioContactLensSeriesName}}</a></li>
                                        </ul>
                                      </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label class="d-flex align-items-center justify-content-between" for="brand">Base Curve <i (click)="getBases('left')" *ngIf="eyeDataOffice?.leftPrescriptionMultiField?.baseCurves"  class="fa-solid fa-rotate-left"></i></label>
                                    <!-- <input type="text" class="form-control" id="brand" placeholder="" disabled> -->
                                    <div class="dropdown">
                                    <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                    [disabled]="eyeDataOffice?.left?.baseCurves?.length==0 || eyeDataOffice?.left?.baseCurves?.length==1 ||!eyeDataOffice?.left?.baseCurves"
                                    >
                                      {{eyeDataOffice?.left?.baseCurveName}}
                                      <del *ngIf="eyeDataPatient?.left?.baseCurveName != eyeDataOffice?.left?.baseCurveName" class="del-text">{{eyeDataPatient?.left?.baseCurveName}}</del>
                                      <img *ngIf="eyeDataOffice?.left?.baseCurves?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                    </button>
                                    <ul class="dropdown-menu w-100">
                                        <li *ngFor="let item of eyeDataOffice?.left?.baseCurves"><a
                                            (click)="setEyeType('left');selectBaseCurveName(item,true,'left')"
                                            class="dropdown-item">{{item?.baseCurveName}}</a></li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label class="d-flex align-items-center justify-content-between" for="brand">Diameter <i (click)="getDiameter('left')" *ngIf="eyeDataOffice?.leftPrescriptionMultiField?.diameters" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.left?.diameters?.length==0 || eyeDataOffice?.left?.diameters?.length==1 ||!eyeDataOffice?.left?.diameters"
                                        >
                                          {{eyeDataOffice?.left?.diameter}}
                                          <del *ngIf="eyeDataPatient?.left?.diameter != eyeDataOffice?.left?.diameter" class="del-text">{{eyeDataPatient?.left?.diameter}}</del>
                                          <img *ngIf="eyeDataOffice?.left?.diameters?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.left?.diameters"><a
                                                (click)="setEyeType('left');selectDiameter(item,true,'left')"
                                                class="dropdown-item">{{item?.diameter}}</a></li>
                                        </ul>
                                        </div>
                                    <!-- <input type="text" class="form-control" id="brand" placeholder="" disabled> -->
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label class="d-flex align-items-center justify-content-between" for="brand">Sphere <i (click)="getSpheres('left')" *ngIf="eyeDataOffice?.leftPrescriptionMultiField?.spheres" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.left?.spheres?.length==0 || eyeDataOffice?.left?.spheres?.length==1 ||!eyeDataOffice?.left?.spheres"
                                        >
                                          {{eyeDataOffice?.left?.sphere}}
                                          <del *ngIf="eyeDataPatient?.left?.sphere != eyeDataOffice?.left?.sphere" class="del-text">{{eyeDataPatient?.left?.sphere}}</del>
                                          <img *ngIf="eyeDataOffice?.left?.spheres?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.left?.spheres"><a
                                                (click)="setEyeType('left');selectSphere(item,true,'left')"
                                                class="dropdown-item">{{item?.sphere}}</a></li>
                                        </ul>
                                        </div>
                            </div>
                        </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label class="d-flex align-items-center justify-content-between" for="brand">Cylinder <i (click)="getCylinders('left')" *ngIf="eyeDataOffice?.leftPrescriptionMultiField?.cylinders" class="fa-solid fa-rotate-left"></i></label>
                                        <div class="dropdown">
                                            <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                            [disabled]="eyeDataOffice?.left?.cylinders?.length==0 || eyeDataOffice?.left?.cylinders?.length==1 ||!eyeDataOffice?.left?.cylinders"
                                            >
                                            {{eyeDataOffice?.left?.cylinder}}
                                            <del *ngIf="eyeDataPatient?.left?.cylinder != eyeDataOffice?.left?.cylinder" class="del-text">{{eyeDataPatient?.left?.cylinder}}</del>
                                            <img *ngIf="eyeDataOffice?.left?.cylinders?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.left?.cylinders"><a
                                                (click)="setEyeType('left');selectCylinder(item,true,'left')"
                                                class="dropdown-item">{{item?.cylinder}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label class="d-flex align-items-center justify-content-between" for="brand">Axis <i (click)="getAxis('left')" *ngIf="eyeDataOffice?.leftPrescriptionMultiField?.axes" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.left?.axis?.length==0 || eyeDataOffice?.left?.axis?.length==1 ||!eyeDataOffice?.left?.axis"
                                        >
                                          {{eyeDataOffice?.left?.axisName}}
                                          <del *ngIf="eyeDataPatient?.left?.axisName != eyeDataOffice?.left?.axisName" class="del-text">{{eyeDataPatient?.left?.axisName}}</del>
                                          <img *ngIf="eyeDataOffice?.left?.axis?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.left?.axis"><a
                                                (click)="setEyeType('left');selectAxis(item,true,'left')"
                                                class="dropdown-item">{{item?.axis}}</a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Add <i (click)="getAdds('left')" *ngIf="eyeDataOffice?.leftPrescriptionMultiField?.adds" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown"></div>
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.left?.adds?.length==0 || eyeDataOffice?.left?.adds?.length==1 ||!eyeDataOffice?.left?.adds"
                                        >
                                          {{eyeDataOffice?.left?.add}}
                                          <del *ngIf="eyeDataPatient?.left?.add != eyeDataOffice?.left?.add" class="del-text">{{eyeDataPatient?.left?.add}}</del>
                                          <img *ngIf="eyeDataOffice?.left?.adds?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.left?.adds"><a
                                                (click)="setEyeType('left');selectAdd(item,true,'left')"
                                                class="dropdown-item">{{item?.add}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            <div class="col-6">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Color <i (click)="getColors('left')" *ngIf="eyeDataOffice?.leftPrescriptionMultiField?.colors" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown">
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.left?.colors?.length==0 || eyeDataOffice?.left?.colors?.length==1 ||!eyeDataOffice?.left?.colors"
                                        >
                                          {{eyeDataOffice?.left?.color}}
                                          <del *ngIf="eyeDataPatient?.left?.color != eyeDataOffice?.left?.color" class="del-text">{{eyeDataPatient?.left?.color}}</del>
                                          <img *ngIf="eyeDataOffice?.left?.colors?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.left?.colors"><a
                                                (click)="setEyeType('left');selectColor(item,true,'left')"
                                                class="dropdown-item">{{item?.color}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group mb-4">
                                    <label for="brand" class="d-flex align-items-center justify-content-between">Lens Count <i (click)="getLensCounts('left')" *ngIf="eyeDataOffice?.leftPrescriptionMultiField?.lensCounts" class="fa-solid fa-rotate-left"></i></label>
                                    <div class="dropdown"></div>
                                        <button type="button" class="form-control dropdown-toggle" data-bs-toggle="dropdown" 
                                        [disabled]="eyeDataOffice?.left?.quantities?.length==0 || eyeDataOffice?.left?.quantities?.length==1 ||!eyeDataOffice?.left?.quantities"
                                        >
                                          {{eyeDataOffice?.left?.quantity}}
                                          <del *ngIf="eyeDataPatient?.left?.quantity != eyeDataOffice?.left?.quantity" class="del-text">{{eyeDataPatient?.left?.quantity}}</del>
                                          <img *ngIf="eyeDataOffice?.left?.quantities?.length>1" src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                            <li *ngFor="let item of eyeDataOffice?.left?.lensCounts"><a
                                                (click)="setEyeType('left');selectLensCount(item,true,'left')"
                                                class="dropdown-item">{{item?.lensCount}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row">
                    
                        <div class="col-12 col-md-6" >
                            <!-- <div class="row">
                                <div class="col-6">
                                   
                                    <span class="link-flag w-100 text-center"><span style="cursor: pointer;"><img src="assets/images/link-flag.svg" /><a href={{patientDetails?.ePrescription}} target="_blank">{{item.patientId.ePrescriptionName}}</a></span></span>
                                </div>
                            </div> -->
                            <div class="row upload-prescription-row" *ngIf="patientDetails?.ePrescription">
                                <!-- Mobile Upload -->
                                <div class="col-6" *ngIf="prescriptionUrl">
                                   
                                    <span class="link-flag w-100 text-center">
                                        <img class="remove-doc" src="assets/images/close.png" (click)="onRemovePrescription()"/>
                                        <span style="cursor: pointer;"><img src="assets/images/link-flag.svg" /><a href={{prescriptionUrl}} target="_blank">{{ePrescriptionName}}</a></span></span>
                                </div>
                                <div class="col-md-6 d-block d-md-none" *ngIf="!prescriptionUrl">
                                    <!-- <label class="mb-2">Open Prescription</label> -->
                                  <div  class="form-group file-upload-group">
                                    <input type="file" (change)="onAttachFile($event)"/>
                                    <div class="file-wrap">
                                      <div class="file-upload-wrap py-2 px-3  text-center">
                                        <img class="me-2" src="assets/images/camera.svg" />
                                        <span>Take Photo</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>      
                                <!-- Desktop Upload -->
                                <div class="col-md-6 d-none d-md-block" *ngIf="!prescriptionUrl">
                                    <!-- <label class="mb-2">Open Prescription</label> -->
                                  <div class="form-group file-upload-group">
                                    <input type="file" (change)="onAttachFile($event)"/>
                                    <div class="file-wrap">
                                      <div class="file-upload-wrap py-2 px-3 text-center">
                                        <img class="me-3" src="assets/images/paper-clip.svg" />
                                        <span>Upload File</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                        </div>
                        <div class="col-12 col-md-6 text-end gap-3 mt-md-0 mt-4">
                            <div class="d-flex align-items-center justify-content-md-end gap-3">
                                <button [class.disabled]="disableRxDeny()" style="width: 120px;"  class="btn btn-danger" (click)="openDenialPopup()">Deny</button>
                                <button [class.disabled]="disableRxHold()" style="width: 120px;" class="btn btn-info text-white" (click)="changeJobStatus('Hold Prescription')">Hold</button>
                                <button [class.disabled]="disableRxVerify()" style="width: 120px;" class="btn btn-success" (click)="onConfirmPrescriptionInfo()">Verify</button>
                            </div>
                        </div>
                      </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" [ngStyle]="{'background-color': confirmFinancialInfoStepActive ? '#DAFDC0' : ''}">
                <a (click)="resetConfirmFinancialInfo();" class="collapsed btn d-flex align-items-center justify-content-between" data-bs-toggle="collapse" href="#collapseThree">
                 <span>3. Financial Data</span>
               <button class="btn btn-info" *ngIf="patientDetails?.eStatus=='existing' && !insuranceApplied_Office">Existing Patient - No Insurance Verification Needed</button>
               <button class="btn btn-danger" *ngIf="item.webstoreTicketStatus==='Cancelled'">Cancelled Order - Refund Issued</button>
               <button *ngIf="item.webstoreTicketStatus=='Denied'" class="btn btn-danger">Rx Denied - Refund Issued</button>


               <i *ngIf="patientDetails?.eStatus =='new'" class="fa-solid fa-pen-to-square"></i>
                </a>
              </div>
              <div id="collapseThree" class="collapse" data-bs-parent="#accordion" [class.active]="!confirmFinancialInfo">
                <div class="card-body px-lg-4">
                    <div class="row align-items-center">
                        <div class="col-xl-6 pe-xl-0">
                            <p class="mb-0 financial-text">Below is the financial summary for this order.</p>
                        </div>
                        <div class="col-xl-6 mt-xl-0 mt-4">
                                <div class="d-flex align-items-center gap-3 justify-content-xl-end no-action-btn">
                                    <button class="btn btn-primary" style="cursor: initial;">{{item?.shipType=='DTO'?'Direct to Office (DTO)':'Direct to Patient (DTP)'}}</button>
                                <button class="btn btn-primary" style="cursor: initial;"><span style="text-transform: capitalize;">{{item?.deliveryTime =="secondDay"?"Two Day": item?.deliveryTime =="nextDay"?"One Day":item?.deliveryTime }}</span> Speed</button>
                                <button class="btn btn-primary" style="cursor: initial;">{{item?.eSupply}} Month Supply</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6 no-lock-eye" [class.lock-eye]="lockRightEye">
                                <div class="lens-card mt-4">
                                    <div class="lens-card-header">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h5 class="eye-title-text">Right Eye (OD)</h5>
                                            <span>
                                                <del class="del-text-danger me-lg-3 me-2" *ngIf="eyeDataPatient?.right?.quantity != eyeDataOffice?.right?.quantity">{{eyeDataPatient?.right?.quantity || 0}} Boxes</del> 
                                                {{eyeDataOffice?.right?.quantity || 0}} Boxes
                                            </span>
                                        </div>
                                        <p>{{eyeDataOffice?.right?.style}} <del class="del-text-danger ms-lg-3 ms-2" *ngIf="eyeDataPatient?.right?.style != eyeDataOffice?.right?.style">{{eyeDataPatient?.right?.style}}</del></p>
                                    </div>
                                    <div class="lens-card-body">
                                        <ul class="lens-card-list">
                                            <li>
                                                <span>Retail Price</span>
                                                <span>
                                                    <del *ngIf="pricingDataPatient?.rightLensCalculations?.grossPrice != pricingDataOffice?.rightLensCalculations?.grossPrice" class="del-text-danger me-lg-3 me-2">
                                                        {{ pricingDataPatient?.rightLensCalculations?.grossPrice | currency:'USD' }}
                                                    </del>
                                                    {{ pricingDataOffice?.rightLensCalculations?.grossPrice || 0 | currency:'USD' }}
                                                </span>
                                            </li>
                                            <li><span>Insurance Allowance</span>
                                                <span>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.rightLensCalculations?.insuranceAllowance != pricingDataOffice?.rightLensCalculations?.insuranceAllowance">
                                                        -{{pricingDataPatient?.rightLensCalculations?.insuranceAllowance || 0 | number:'1.2-2' | currency:'USD'}}
                                                    </del>
                                                    -{{pricingDataOffice?.rightLensCalculations?.insuranceAllowance || 0 | number:'1.2-2' | currency:'USD'}}
                                                </span>
                                            </li>
                                            <li><span>Insurance Copay</span>
                                                <span>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.rightLensCalculations?.insuranceCopay != pricingDataOffice?.rightLensCalculations?.insuranceCopay">
                                                        {{pricingDataPatient?.rightLensCalculations?.insuranceCopay || 0 | number:'1.2-2' | currency:'USD'}}
                                                    </del>
                                                    {{pricingDataOffice?.rightLensCalculations?.insuranceCopay || 0 | number:'1.2-2' | currency:'USD'}}
                                                </span>
                                            </li>
                                            <li><span>Insurance Discount</span>
                                                    <span>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.rightLensCalculations?.overageCoInsurance != pricingDataOffice?.rightLensCalculations?.overageCoInsurance">
                                                        {{pricingDataPatient?.rightLensCalculations?.overageCoInsurance || 0 | number:'1.2-2' | currency:'USD'}}
                                                    </del>
                                                    {{pricingDataOffice?.rightLensCalculations?.overageCoInsurance || 0 | number:'1.2-2' | currency:'USD'}}
                                                </span>
                                            </li>
                                            <li>
                                                <span>Discount Savings</span>
                                                <span>
                                                    <del *ngIf="pricingDataPatient?.rightLensCalculations?.discount != pricingDataOffice?.rightLensCalculations?.discount" class="del-text-danger me-lg-3 me-2">
                                                        {{ pricingDataPatient?.rightLensCalculations?.discount | currency:'USD' }}
                                                    </del>
                                                    -{{ pricingDataOffice?.rightLensCalculations?.discount || 0 | number:'1.2-2' | currency:'USD' }}
                                                </span>
                                            </li>
                                            <li>
                                                <span>Shipping Fees</span>
                                                <span>
                                                    <del *ngIf="pricingDataPatient?.rightLensCalculations?.shippingFee != pricingDataOffice?.rightLensCalculations?.shippingFee" class="del-text-danger me-lg-3 me-2">
                                                        {{ pricingDataPatient?.rightLensCalculations?.shippingFee | currency:'USD' }}
                                                    </del>
                                                    
                                                    {{ pricingDataOffice?.rightLensCalculations?.shippingFee || 0 | number:'1.2-2' | currency:'USD' }}</span>
                                            </li>
                                            <li>
                                                <span>Tax</span>
                                                <span>
                                                    <del *ngIf="pricingDataPatient?.rightLensCalculations?.tax != pricingDataOffice?.rightLensCalculations?.tax" class="del-text-danger me-lg-3 me-2">
                                                        {{ pricingDataPatient?.rightLensCalculations?.tax | currency:'USD' }}
                                                    </del>
                                                    
                                                    {{ pricingDataOffice?.rightLensCalculations?.tax || 0 | number:'1.2-2' | currency:'USD' }}</span>
                                            </li>
                                            <li>
                                                <span>Processing Fees</span>
                                                <span>
                                                    <del *ngIf="pricingDataPatient?.rightLensCalculations?.processingFee != pricingDataOffice?.rightLensCalculations?.processingFee" class="del-text-danger me-lg-3 me-2">
                                                        {{ pricingDataPatient?.rightLensCalculations?.processingFee | currency:'USD' }}
                                                    </del>
                                                    {{ pricingDataOffice?.rightLensCalculations?.processingFee || 0 | number:'1.2-2' | currency:'USD' }}</span>
                                            </li>
                                            <li>
                                                <strong>Total Due Today</strong>
                                                <strong>
                                                    <del *ngIf="pricingDataPatient?.rightLensCalculations?.totalDueToday != pricingDataOffice?.rightLensCalculations?.totalDueToday" class="del-text-danger me-lg-3 me-2">
                                                        {{ pricingDataPatient?.rightLensCalculations?.totalDueToday | currency:'USD' }}
                                                    </del>
                                                    {{ pricingDataOffice?.rightLensCalculations?.totalDueToday || 0 | currency:'USD' }}
                                                </strong>
                                            </li>
                                            <li>
                                                <span>Rebate Savings</span>
                                                <span>
                                                    <del *ngIf="pricingDataPatient?.rightLensCalculations?.rebate != pricingDataOffice?.rightLensCalculations?.rebate" class="del-text-danger me-lg-3 me-2">
                                                        -{{ pricingDataPatient?.rightLensCalculations?.rebate | number:'1.2-2' | currency:'USD' }}
                                                    </del>
                                                    -{{ pricingDataOffice?.rightLensCalculations?.rebate || 0 | number:'1.2-2' | currency:'USD' }}
                                                </span>
                                            </li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 no-lock-eye" [class.lock-eye]="lockLeftEye">
                                <div class="lens-card mt-4">
                                    <div class="lens-card-header">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <h5 class="eye-title-text">Left Eye (OD)</h5>
                                            <span><del class="del-text-danger me-lg-3 me-2" *ngIf="eyeDataPatient?.left?.quantity != eyeDataOffice?.left?.quantity">{{eyeDataPatient?.left?.quantity || 0}} Boxes</del> {{eyeDataOffice?.left?.quantity || 0}} Boxes</span>
                                        </div>
                                        <p>{{eyeDataOffice?.left?.style}} <del class="del-text-danger ms-lg-3 ms-2" *ngIf="eyeDataPatient?.left?.style != eyeDataOffice?.left?.style">{{eyeDataPatient?.left?.style}}</del></p>
                                    </div>
                                    <div class="lens-card-body">
                                        <ul class="lens-card-list">
                                            <li>
                                                <span>Retail Price</span>
                                                <span>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.leftLensCalculations?.grossPrice != pricingDataOffice?.leftLensCalculations?.grossPrice">
                                                        {{ pricingDataPatient?.leftLensCalculations?.grossPrice | currency:'USD' }}
                                                    </del>
                                                    {{ pricingDataOffice?.leftLensCalculations?.grossPrice | currency:'USD' }}
                                                </span>
                                            </li>
                                            <li><span>Insurance Allowance</span>
                                                <span>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.leftLensCalculations?.insuranceAllowance != pricingDataOffice?.leftLensCalculations?.insuranceAllowance">
                                                        {{pricingDataPatient?.leftLensCalculations?.insuranceAllowance || 0 | number:'1.2-2' | currency:'USD'}}
                                                    </del>
                                                    -{{pricingDataOffice?.leftLensCalculations?.insuranceAllowance || 0 | number:'1.2-2' | currency:'USD'}}</span></li>
                                            <li><span>Insurance Copay</span><span>
                                                <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.leftLensCalculations?.insuranceCopay != pricingDataOffice?.leftLensCalculations?.insuranceCopay">
                                                    {{pricingDataPatient?.leftLensCalculations?.insuranceCopay || 0 | number:'1.2-2' | currency:'USD'}}
                                                </del>
                                                {{pricingDataOffice?.leftLensCalculations?.insuranceCopay || 0 | number:'1.2-2' | currency:'USD'}}</span></li>
                                            <li><span>Insurance Discount</span><span>
                                                <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.leftLensCalculations?.overageCoInsurance != pricingDataOffice?.leftLensCalculations?.overageCoInsurance">
                                                    {{pricingDataPatient?.leftLensCalculations?.overageCoInsurance || 0 | number:'1.2-2' | currency:'USD'}}
                                                </del>
                                                {{pricingDataOffice?.leftLensCalculations?.overageCoInsurance || 0 | number:'1.2-2' | currency:'USD'}}</span></li>
                                            <li>
                                                <span>Discount Savings</span>
                                               
                                                <span>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.leftLensCalculations?.discount != pricingDataOffice?.leftLensCalculations?.discount">
                                                        {{ pricingDataPatient?.leftLensCalculations?.discount | currency:'USD' }}
                                                    </del>
                                                    -{{ pricingDataOffice?.leftLensCalculations?.discount || 0 | number:'1.2-2' | currency:'USD' }}</span>
                                            </li>
                                            <li>
                                                <span>Shipping Fees</span>
                                                <span>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.leftLensCalculations?.shippingFee != pricingDataOffice?.leftLensCalculations?.shippingFee">
                                                        {{ pricingDataPatient?.leftLensCalculations?.shippingFee | currency:'USD'}}
                                                        </del>
                                                        {{ pricingDataOffice?.leftLensCalculations?.shippingFee || 0 | number:'1.2-2' | currency:'USD' }}</span>
                                            </li>
                                            <li>
                                                <span>Tax</span>
                                                
                                                <span>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.leftLensCalculations?.tax != pricingDataOffice?.leftLensCalculations?.tax">
                                                        {{ pricingDataPatient?.leftLensCalculations?.tax | currency:'USD'}}
                                                    </del>
                                                    {{ pricingDataOffice?.leftLensCalculations?.tax || 0 | number:'1.2-2' | currency:'USD' }}</span>
                                            </li>
                                            <li>
                                                <span>Processing Fees</span>
                                                
                                                <span>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.leftLensCalculations?.processingFee != pricingDataOffice?.leftLensCalculations?.processingFee">
                                                        {{ pricingDataPatient?.leftLensCalculations?.processingFee | currency:'USD'}}
                                                    </del>
                                                    {{ pricingDataOffice?.leftLensCalculations?.processingFee || 0 | number:'1.2-2' | currency:'USD' }}</span>
                                            </li>
                                            <li>
                                                <strong>Total Due Today</strong>
                                                <strong>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.leftLensCalculations?.totalDueToday != pricingDataOffice?.leftLensCalculations?.totalDueToday">
                                                        {{ pricingDataPatient?.leftLensCalculations?.totalDueToday | currency:'USD' }}
                                                    </del>
                                                    {{ pricingDataOffice?.leftLensCalculations?.totalDueToday  | currency:'USD' }}
                                                </strong>
                                            </li>
                                            <li>
                                                <span>Rebate Savings</span>
                                                <span>
                                                    <del class="del-text-danger me-lg-3 me-2" *ngIf="pricingDataPatient?.leftLensCalculations?.rebate !== pricingDataOffice?.leftLensCalculations?.rebate">
                                                        -{{ pricingDataPatient?.leftLensCalculations?.rebate| number:'1.2-2' | currency:'USD' }}
                                                    </del>
                                                    -{{ pricingDataOffice?.leftLensCalculations?.rebate || 0 | number:'1.2-2' | currency:'USD' }}
                                                </span>
                                            </li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group appearnace-group mt-4">
                                    
                                    <div class="d-flex align-items-center gap-3">
                                        <div>
                                            <label class="opacity-100">Total Due Today</label>
                                            <div class="input-appearance position-relative">
                                                <del *ngIf="item.Soft_CL_Sales_Amount != Soft_CL_Sales_Amount_Office" class="del-text-danger me-lg-3 me-2">{{item.Soft_CL_Sales_Amount | number:'1.2-2'}}</del>
                                                <span>{{Soft_CL_Sales_Amount_Office | number:'1.2-2'}}</span>
                                                <span class="dollar-icon-right">$</span>
                                            </div>
                                        </div>
                                    <div *ngIf="item.updatedData?.couponCode">
                                        <label class="opacity-100" >Discount Code Used</label>
                                    <button class="btn coupon-btn gap-2">
                                    <i class="fa fa-tags" aria-hidden="true"></i>{{item.updatedData?.couponCode}} 
                                    <span *ngIf="couponDetails?.couponType === 'percent'">
                                        ({{couponDetails?.couponValue | number:'1.2-2'}}% OFF)
                                    </span>
                                    <span *ngIf="couponDetails?.couponType === 'fixed'">
                                            (${{couponDetails?.couponValue | number:'1.2-2'}} OFF)
                                    </span></button>
                                    </div>
                                    <!-- <div class="net-difference">
                                        <span>Net Difference</span>
                                        <span>{{netDifference | number:'1.2-2' | currency:'USD'}}</span>
                                    </div> -->
                                </div>
                            </div>
                            </div>
                            <div *ngIf="Soft_CL_Insurance_Carrier_Office">
                            <div class="col-12">
                                <div class="form-group insurance-group mt-4">
                                    <label class="opacity-100">Because the patient self-elected to add their vision insurance, please verify their stated eligibility.</label>
                                    <div class="iinsurance-wrapper">
                                        <div class="d-flex align-items-center gap-4">
                                            <div class="insurance-icon">
                                                <img src={{inssuranceCarrierImage}} alt="Insurance Icon" />
                                            </div>
                                            
                                            <div class="insurance-text d-flex align-items-center gap-1" *ngIf="insurranceFieldType == 'insurranceWithNewCarrier'">
                                                Carrier: <del class="del-text-danger" *ngIf="Soft_CL_Insurance_Carrier_Patient !== Soft_CL_Insurance_Carrier_Office && insurranceFieldType == 'insurranceWithNewCarrier'">{{Soft_CL_Insurance_Carrier_Patient}}</del><strong>{{Soft_CL_Insurance_Carrier_Office}}</strong>
                                            </div>
                                            <div class="insurance-text d-flex align-items-center gap-1" *ngIf=" insurranceFieldType == 'insurranceWithExistingCarrier' || insurranceFieldType == 'noInsurance'">
                                                Carrier: <strong>{{Soft_CL_Insurance_Carrier_Patient}}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group radio-group mt-4 mb-4">
                                    <div class="select-type">
                                        <div>
                                            <div class="radio-item position-relative mb-2">
                                                <input class="form-radio" [disabled]="item.webstoreTicketStatus=='Completed' || item.webstoreTicketStatus=='Cancelled' || item.webstoreTicketStatus=='Denied' " type="radio" name="eligibility_type" id="Eligibility"
                                                    value="eligibility_type" (click)="setInsurranceField('insurranceWithExistingCarrier')"/>
                                                <label class="radio-label opacity-100 mb-0" >Yes, the patient is eligible to use benefits.</label>
                                                <span class="radio-icon"></span>
                                                <div class="radio-item-content mt-4">
                                                    <div class="row">
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group mb-4">
                                                                <label class="mb-1">Insurance Type</label>
                                                                <div class="dropdown">
                                                                    <button class="form-control dropdown-toggle" data-bs-toggle="dropdown">
                                                                        {{insuranceType}}<img src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                                                    </button>
                                                                    <ul class="dropdown-menu w-100">
                                                                        <li (click)="setInsuranceType('In Network')"><a class="dropdown-item" href="javascript:void(0)">In Network</a></li>
                                                                        <li (click)="setInsuranceType('Out of Network')"><a class="dropdown-item" href="javascript:void(0)">Out of Network</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group mb-4">
                                                                <label class="mb-1">Out of Network Type</label>
                                                                <div class="dropdown">
                                                                    <button class="form-control dropdown-toggle" [class.disabled-control]="insuranceType !== 'Out of Network'" data-bs-toggle="dropdown">
                                                                       {{outOfNetworkType}}<img src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                                                    </button>
                                                                    <ul class="dropdown-menu w-100">
                                                                        <li (click)="setOutOfNetworkType('Patient Reimburse')"><a class="dropdown-item" href="javascript:void(0)">Patient Reimburse</a></li>
                                                                        <li (click)="setOutOfNetworkType('Practice Reimburse')"><a class="dropdown-item" href="javascript:void(0)">Practice Reimburse</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group mb-4">
                                                                <label class="mb-1">Formulary Lens</label>
                                                                <div class="dropdown">
                                                                    <button class="form-control dropdown-toggle" [class.disabled-control]="Soft_CL_Insurance_Carrier_Office !== 'Spectera'" data-bs-toggle="dropdown">
                                                                        {{formularyLens}}<img src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                                                    </button>
                                                                    <ul class="dropdown-menu w-100">
                                                                        <li (click)="setFormularyLens('Yes')"><a class="dropdown-item" href="javascript:void(0)">Yes</a></li>
                                                                        <li (click)="setFormularyLens('No')"><a class="dropdown-item" href="javascript:void(0)">No</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group icon-group-currency mb-4">
                                                                <label class="mb-1">Insurance Allowance</label>
                                                                <input type="text" class="form-control" [(ngModel)]="officeInsuranceAllowance" (blur)="formatToTwoDecimalPlaces($event)"/>
                                                                <span>$</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group icon-group-currency mb-4">
                                                                <label class="mb-1">Insurance Copay</label>
                                                                <input type="text" class="form-control" [(ngModel)]="officeInsuranceCopay" (blur)="formatToTwoDecimalPlaces($event)"/>
                                                                <span>$</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="form-group icon-group-currency mb-4">
                                                                <label class="mb-1">Insurance Discount</label>
                                                                <input type="text" class="form-control" [(ngModel)]="officeInsurranceDiscount" (blur)="formatToTwoDecimalPlaces($event)"/>
                                                                <span>%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       <div>
                                        <div class="radio-item position-relative mb-2">
                                            <input class="form-radio" [disabled]="item.webstoreTicketStatus=='Completed' || item.webstoreTicketStatus=='Cancelled' || item.webstoreTicketStatus=='Denied' " type="radio" name="eligibility_type" id="Eligibility1"
                                                value="eligibility_type" (click)="setInsurranceField('insurranceWithNewCarrier')"/>
                                            <label class="radio-label opacity-100 mb-0">Yes, the patient is eligible to use benefits, but through a different carrier.</label>
                                            <span class="radio-icon"></span>
                                            <div class="radio-item-content mt-4">
                                                <div class="row">
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group mb-4">
                                                            <label class="mb-1">Insurance Type</label>
                                                                <div class="dropdown">
                                                                    <button class="form-control dropdown-toggle" data-bs-toggle="dropdown">
                                                                        {{insuranceType}}<img src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                                                    </button>
                                                                    <ul class="dropdown-menu w-100">
                                                                        <li (click)="setInsuranceType('In Network')"><a class="dropdown-item" href="javascript:void(0)">In Network</a></li>
                                                                        <li (click)="setInsuranceType('Out of Network')"><a class="dropdown-item" href="javascript:void(0)">Out of Network</a></li>
                                                                    </ul>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group mb-4">
                                                            <label class="mb-1">Carrier</label>
                                                            <div class="dropdown">
                                                                <button class="form-control dropdown-toggle" data-bs-toggle="dropdown">
                                                                    {{Soft_CL_Insurance_Carrier_Office}}<img src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                                                </button>
                                                                <ul class="dropdown-menu w-100">
                                                                    <li *ngFor="let carrier of insurranceCarrierList" (click)="setInsuranceCarrier(carrier.title)">
                                                                        <a class="dropdown-item" href="javascript:void(0)">{{ carrier.title }}</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group mb-4">
                                                            <label class="mb-1">Out of Network Type</label>
                                                            <div class="dropdown">
                                                                <button class="form-control dropdown-toggle" [class.disabled-control]="insuranceType !== 'Out of Network'" data-bs-toggle="dropdown">
                                                                   {{outOfNetworkType}}<img src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                                                </button>
                                                                <ul class="dropdown-menu w-100">
                                                                    <li (click)="setOutOfNetworkType('Patient Reimburse')"><a class="dropdown-item" href="javascript:void(0)">Patient Reimburse</a></li>
                                                                    <li (click)="setOutOfNetworkType('Practice Reimburse')"><a class="dropdown-item" href="javascript:void(0)">Practice Reimburse</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group mb-4">
                                                            <label class="mb-1">Formulary Lens</label>
                                                                <div class="dropdown">
                                                                    <button class="form-control dropdown-toggle"  [class.disabled-control]="Soft_CL_Insurance_Carrier_Office !== 'Spectera'" data-bs-toggle="dropdown">
                                                                        {{formularyLens}}<img src="assets/images/dropdown-arrow-grey.svg" alt="Down Arrow" />
                                                                    </button>
                                                                    <ul class="dropdown-menu w-100">
                                                                        <li (click)="setFormularyLens('Yes')"><a class="dropdown-item" href="javascript:void(0)">Yes</a></li>
                                                                        <li (click)="setFormularyLens('No')"><a class="dropdown-item" href="javascript:void(0)">No</a></li>
                                                                    </ul>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group icon-group-currency mb-4">
                                                            <label class="mb-1">Insurance Allowance</label>
                                                            <input type="text" class="form-control" [(ngModel)]="officeInsuranceAllowance" (blur)="formatToTwoDecimalPlaces($event)"/>
                                                            <span>$</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group icon-group-currency mb-4">
                                                            <label class="mb-1">Insurance Copay</label>
                                                            <input type="text" class="form-control" [(ngModel)]="officeInsuranceCopay" (blur)="formatToTwoDecimalPlaces($event)"/>
                                                            <span>$</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group icon-group-currency mb-4">
                                                            <label class="mb-1">Insurance Discount</label>
                                                            <input type="text" class="form-control" [(ngModel)]="officeInsurranceDiscount" (blur)="formatToTwoDecimalPlaces($event)"/>
                                                            <span>%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       </div>
                                        <div>
                                            <div class="radio-item position-relative mb-2">
                                                <input [disabled]="item.webstoreTicketStatus=='Completed' || item.webstoreTicketStatus=='Cancelled' || item.webstoreTicketStatus=='Denied' " class="form-radio" type="radio" name="eligibility_type" id="Eligibility2"
                                                    value="eligibility_type" (click)="setInsurranceField('noInsurance')"/>
                                                <label class="radio-label opacity-100 mb-0">No, the patient is not eligible to use the stated benefits.</label>
                                                <span class="radio-icon"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <!-- <div class="col-12" *ngIf="adjustmentPayment==='charge'">
                                <p class="charge-text">Given the lenses associated with the order changed and patient was not eligible for their stated vision insurance plan, there is a <strong>net difference of {{netDifference | number:'1.2-2' | currency:'USD'}} that needs to be charged</strong> to the patient. Please proceed with initiating the charge below and customizing the message sent.</p>
                            </div> -->
                        </div>
                        <p *ngIf="dynamicText" class="mt-3">{{ dynamicText }}</p>
                        <!-- <p *ngIf="patientDetails?.eStatus=='existing' && insurranceFieldType == 'insurranceWithExistingCarrier' && adjustmentPayment" class="mt-3">Given the patient is eligible for their stated vision insurance plan, there is a net difference of ${{netDifference| number:'1.2-2'}} that needs to be charged to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of their coverage and incoming charge.</p>
                        <p *ngIf="patientDetails?.eStatus=='existing' && insurranceFieldType == 'insurranceWithNewCarrier' && adjustmentPayment" class="mt-3">Given the patient is eligible for the updated vision insurance plan, there is a net difference of ${{netDifference | number:'1.2-2'}} that needs to be {{adjustmentPayment == 'refunded' ? 'refunded' : 'charged'}} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of their coverage and incoming {{adjustmentPayment == 'refunded' ? 'refund' : 'charge' }}.</p>
                       <p *ngIf="patientDetails?.eStatus=='existing' && insurranceFieldType == 'noInsurance' " class="mt-3">Given the patient is not eligible for their stated vision insurance plan, there are no changes, financially, needed for this order. Please proceed with completing the order below. We’ll notify the patient of their ineligible status and process the order accordingly.</p>
                        <p *ngIf="patientDetails?.eStatus=='new' && !insuranceApplied_Office && !(isleftPrescriptionChanged && isrightPrescriptionChanged)" class="mt-3">Given there were no changes to the patient's provided prescription and the patient elected to not use insurance, there are no financial changes needed. Please proceed with completing the order below. We'll send a message to the patient, automatically, on your behalf notifying them their order is processing.</p>
                       <p *ngIf="patientDetails?.eStatus=='new' && insurranceFieldType == 'insurranceWithExistingCarrier' && !(isleftPrescriptionChanged && isrightPrescriptionChanged) && adjustmentPayment" class="mt-3">There were no changes to the patient's provided prescription but the patient elected to use insurance. Based on your prompt answers, the patient is eligible for their stated vision insurance plan, resulting in a net difference of ${{netDifference | number:'1.2-2'}} that needs to be {{adjustmentPayment}} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of their coverage and incoming {{adjustmentPayment == 'refunded' ? 'refund' : 'charge' }}.</p>
                       <p *ngIf="patientDetails?.eStatus=='new' && insurranceFieldType == 'insurranceWithNewCarrier' && !(isleftPrescriptionChanged && isrightPrescriptionChanged) && adjustmentPayment" class="mt-3">There were no changes to the patient's provided prescription but the patient elected to use insurance. Based on your prompt answers, the patient is eligible for the updated vision insurance plan, resulting in a net difference of ${{netDifference | number:'1.2-2'}} that needs to be {{adjustmentPayment}} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of their coverage and incoming {{adjustmentPayment == 'refunded' ? 'refund' : 'charge' }}.</p>
                       <p *ngIf="patientDetails?.eStatus=='new' && insurranceFieldType == 'noInsurance' && !(isleftPrescriptionChanged && isrightPrescriptionChanged)" class="mt-3">There were no changes to the patient's provided prescription but the patient elected to use insurance. Based on your prompt answers, the patient is not eligible for their stated vision insurance plan, and as a result, there are no financial changes needed for this order. Please proceed with completing the order below. We’ll notify the patient of their ineligible status and process the order accordingly.</p>
                     
                       <p *ngIf="patientDetails?.eStatus=='new' && !insuranceApplied_Office && (isleftPrescriptionChanged || isrightPrescriptionChanged) && adjustmentPayment" class="mt-3">The patient elected to not use insurance but there were changes to the patient's provided prescription. The prescription changes resulted in a net difference of ${{netDifference | number:'1.2-2'}} that needs to be {{adjustmentPayment}} to the patient. Please proceed with completing the order below. We'll send a message to the patient, automatically, on your behalf notifying them of the {{adjustmentPayment==='refunded'?'refund':'charge'}}.</p>
                       <p *ngIf="patientDetails?.eStatus=='new' && insurranceFieldType == 'insurranceWithExistingCarrier' && (isleftPrescriptionChanged || isrightPrescriptionChanged) && adjustmentPayment" class="mt-3">There were changes to the patient's provided prescription and the patient elected to use insurance. Based on both changes and your prompt answers, there is a difference of ${{netDifference | number:'1.2-2'}} that needs to be {{adjustmentPayment}} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of the {{adjustmentPayment==='refunded'?'refund':'charge'}}.</p>
                       <p *ngIf="patientDetails?.eStatus=='new' && insurranceFieldType == 'insurranceWithNewCarrier' && (isleftPrescriptionChanged || isrightPrescriptionChanged) && adjustmentPayment" class="mt-3">There were changes to the patient's provided prescription and the patient elected to use insurance. Based on both changes and your prompt answers, there is a difference of ${{netDifference | number:'1.2-2'}} that needs to be {{adjustmentPayment}} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of the {{adjustmentPayment==='refunded'?'refund':'charge'}}.</p>
                       <p *ngIf="patientDetails?.eStatus=='new' && insurranceFieldType == 'noInsurance' && (isleftPrescriptionChanged || isrightPrescriptionChanged) && adjustmentPayment" class="mt-3">There were changes to the patient's provided prescription and the patient elected to use insurance. While the patient is not eligible for their stated vision insurance plan, the prescription changes resulted in a net difference of ${{netDifference | number:'1.2-2'}} that needs to be {{adjustmentPayment}} to the patient. Please proceed with completing the order below. We’ll send a message to the patient, automatically, on your behalf notifying them of the {{adjustmentPayment==='refunded'?'refund':'charge'}}.</p>
     -->
                        <!-- <div class="row" *ngIf="adjustmentPayment==='refund'">
                            <p>Because the patient was eligible for vision benefits, there is a <strong>net difference of {{(netDifference) | number:'1.2-2' | currency:'USD'}} that needs to be refunded</strong> to the patient. Please proceed with initiating the refund below... we’ll send a message to the patient on your behalf. </p>
                        </div> -->
    
                        <!-- <h1>{{adjustmentPayment}}</h1> -->
                        <div class="row" *ngIf="adjustmentPayment==='charged'">
                            <div class="col-12 col-md-6">
                                <div class="form-group mb-4 icon-group-currency">
                                    <label class="opacity-100 mb-1">Original Charge</label>
                                    <input type="text" class="form-control disabled-control" [value]="item.Soft_CL_Sales_Amount | number:'1.2-2'" />
                                    <span>$</span>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group mb-4 icon-group-payment">
                                    <label class="opacity-100 mb-1">Original Payment Type</label>
                                    <input type="text" class="form-control disabled-control" [(ngModel)]="item.Soft_CL_Card_Number" />
                                    <img src="assets/images/visa-icon.png" alt="Insurance Icon" />
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group mb-4 icon-group-currency">
                                    <label class="opacity-100 mb-1">Charge Amount</label>
                                    <input type="text" class="form-control warning-control" [value]="(netDifference) | number:'1.2-2'" />
                                    <span>$</span>
                                </div>
                            </div>
                            <!-- <div class="col-12 col-md-6">
                                <div class="form-group mb-4">
                                    <label class="opacity-100">Patient Charge Template</label>
                                    <textarea type="text" class="form-control disabled-control" rows="3" [(ngModel)]="chargeCommunicationMsg"></textarea>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group mb-4">
                                    <label class="opacity-100">Patient Additional Message</label>
                                    <textarea type="text" class="form-control" rows="3" [(ngModel)]="chargeAdditionalMsg"></textarea>
                                </div>
                            </div> -->
                            <div class="col-12 col-md-6">
                                <div class="form-group mb-4">
                                    <label class="opacity-100">Internal Notes</label>
                                    <textarea type="text" class="form-control" rows="2" style="max-height: 40px;" [(ngModel)]="chargeInternalNotes"></textarea>
                                </div>
                            </div>
                           
                        </div>
                        <div class="row" *ngIf="adjustmentPayment==='refunded'">
                            <div class="col-6 col-md-6">
                                <div class="form-group mb-4 icon-group-currency">
                                    <label class="opacity-100 mb-1">Original Charge</label>
                                    <input type="text" class="form-control disabled-control" [value]="item.Soft_CL_Sales_Amount | number:'1.2-2'" />
                                    <span>$</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-6">
                                <div class="form-group mb-4 icon-group-payment">
                                    <label class="opacity-100">Original Payment Type</label>
                                    <input type="text" class="form-control disabled-control" [(ngModel)]="item.Soft_CL_Card_Number" />
                                    <img src="assets/images/visa-icon.png" alt="Insurance Icon" />
                                </div>
                            </div>
                            <div class="col-6 col-md-6">
                                <div class="form-group mb-4 icon-group-currency">
                                    <label class="opacity-100 mb-1">Refund Amount</label>
                                    <input type="text" class="form-control warning-control" [value]="(netDifference) | number:'1.2-2'" />
                                    <span>$</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-6">
                                <div class="form-group mb-4">
                                    <label class="opacity-100 mb-1">Internal Notes</label>
                                    <textarea type="text" class="form-control" rows="2" style="max-height: 40px;" [(ngModel)]="refundInternalNotes"></textarea>
                                </div>
                            </div>
                           
                        </div>


                         <p *ngIf="item.webstoreTicketStatus==='Denied'" class="mt-3">Because you denied the patient's prescription, the patient was refunded ${{Soft_CL_Sales_Amount_Office | number:'1.2-2'}}, net of the ${{stripeProcessingFee | number:'1.2-2'}} payment processing fees you incurred. The patient was notified automatically by our system of the denial and refund.</p>
                        <p *ngIf="item.webstoreTicketStatus==='Cancelled'" class="mt-3">Because you cancelled the order, the patient was refunded ${{Soft_CL_Sales_Amount_Office | number:'1.2-2'}}, net of the ${{stripeProcessingFee | number:'1.2-2'}} payment processing fees you incurred. The patient was notified automatically by our system of the canceled order and refund.</p>
                       <p *ngIf="item.webstoreTicketStatus==='Completed' && patientDetails?.eStatus=='existing'" class="mt-3">This was an existing patient purchase without insurance, and thus, no financial changes were needed.</p>
                      
                     
                      <div class="row" *ngIf="item.webstoreTicketStatus==='Denied' || item.webstoreTicketStatus==='Cancelled'">
                            <div class="col-6 col-md-6">
                                <div class="form-group mb-4 icon-group-currency">
                                    <label class="opacity-100 mb-1">Original Charge</label>
                                    <input type="text" class="form-control disabled-control" [value]="item.Soft_CL_Sales_Amount | number:'1.2-2'" />
                                    <span>$</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-6">
                                <div class="form-group mb-4 icon-group-payment">
                                    <label class="opacity-100">Original Payment Type</label>
                                    <input type="text" class="form-control disabled-control" [(ngModel)]="item.Soft_CL_Card_Number" />
                                    <img src="assets/images/visa-icon.png" alt="Insurance Icon" />
                                </div>
                            </div>
                            <div class="col-6 col-md-6">
                                <div class="form-group mb-4 icon-group-currency">
                                    <label class="opacity-100 mb-1">Refund Amount</label>
                                    <input type="text" class="form-control warning-control" [value]="(Soft_CL_Sales_Amount_Office - stripeProcessingFee) | number:'1.2-2'" />
                                    <span>$</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-6">
                                <div class="form-group mb-4 icon-group-currency">
                                    <label class="opacity-100 mb-1">Processing Fee</label>
                                    <input type="text" class="form-control disabled-control" [value]="stripeProcessingFee | number:'1.2-2'" />
                                    <span>$</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-12 gap-3 mt-md-0 mt-4">
                            <div class="d-flex align-items-center justify-content-end gap-3">
                                <button [class.disabled]="disableFinancialCancel()"  class="btn btn-danger" (click)="onCancel()">Cancel Order</button>
                                <button [class.disabled]="disableFinancialHold()" style="width: 140px;" class="btn btn-info text-white" (click)="changeJobStatus('Hold Financial Data')">Hold</button>
                                <button  [class.disabled]="disableFinancialComplete()" *ngIf="item.webstoreTicketStatus !=='Completed'" style="width: 140px;" class="btn btn-success" (click)="onComplete()">Complete</button>
                            </div>
                        </div>
                    </div>
                    
                    </div>
                </div>
              </div>
            </div>