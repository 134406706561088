<!-- Note-->
<div class="chat-wrapper">
    <div class="chat-header">
        <!-- *ngIf="selectedJob?.jobNumber" -->
        <h3 class="chat-title mb-3">
           {{item.patientId.Patient_First_Name}} | #{{item.jobNumber}}
            <!-- {{chatData?.orderDetail?.name}} | #{{ selectedJob?.jobNumber}}  -->
        </h3>
        <!-- <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" (click)="switchTemplate('NOTES')">
                    Notes
                </a>
            </li>
        </ul> -->
        <button type="button" class="btn-close" (click)="closeModal()">
            <img src="assets/images/cross-orange.svg" alt="Close" />
        </button>

    </div>
 
    <div  class="chat-body">
    <div #scrollMe class="chat-content">
        <div class="messages-container">
            <div *ngFor="let message of chatData.orderChats; let i = index"  class="message-wrapper" [class.admin-message]="!message.isAdmin">
                <div class="avatar mt-auto" [ngClass]="{ 'invisible': !(i === chatData.orderChats.length - 1 || chatData.orderChats[i + 1]?.senderId !== message.senderId) }">
                    <img [src]="message?.sendByStore?.profilePicture?.original" class="rounded-circle" style="width: 32px; height: 32px;">
                </div>

                <div class="message">
                    <!-- <div *ngIf="!message?.isAdmin" class="sender-details">
                        <strong>{{message.sender}}</strong>
                        <div class="text-muted small">{{message.email}}</div>
                    </div> -->
                    <div *ngIf="message?.messages" class="message-content p-0">
                        {{ message?.messages }}
                    </div>
                    <div *ngIf="message?.attachment" class="attachment mt-2">
                        <img [src]="message?.attachment" class="attachment-preview">
                    </div>
                </div>

                <div class="message-time">
                    <p>{{ message.createdAt | date: 'hh:mm a' }}</p>
                    <p>{{ message.createdAt | date: 'MM/dd/yy' }}</p>
                </div>
            </div>
        </div>
    </div>

     <!-- Chat Footer -->
    <div class="chat-message">
        <form novalidate>
            <div class="form-group d-flex align-items-center">
                <textarea [(ngModel)]="newMessage" placeholder="Write a message here..."
                    class="form-control flex-grow-1" autofocus (keydown)="handleKeydown($event)" style="overflow-y: auto;"></textarea>
                <a href="javascript://" class="ms-3 btn btn-primary attachment-btn mediaBtn"
                    (click)="fileInput.click()">
                    <img src="assets/images/clip.svg" alt="media" />
                </a>
                <input type="file" #fileInput hidden (change)="onSelectFile($event)" />
                <a class="send-msg-btn ms-3 btn-warning" style="height: 39px;" (click)="sendMessage()"
                    href="javascript://">
                    <img src="assets/images/send-btn.png" alt="send" />
                </a>
            </div>
        </form>
    </div>
</div>
</div>

