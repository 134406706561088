import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-prescription-denial',
  templateUrl: './prescription-denial.component.html',
  styleUrls: ['./prescription-denial.component.css']
})
export class PrescriptionDenialComponent implements OnInit {
  jobDetails:any;
  denialReason:any;
  denialNote:any;
  denialPatientFacingNote:any;
  storeDetails:any;
  stripeProcessingFee:any;
  constructor(
     public bsModalRef: BsModalRef,
     private api:ApiService,
     private apiNotification:NotificationService,
     private toastr:ToastrService
     ) { }

  ngOnInit(): void {
      console.log("jobDetails",this.jobDetails);
      this.stripeProcessingFee = JSON.parse(JSON.stringify(this.jobDetails.untrackedPayments[0].FEE));
  }

  denyPrescription(){
    console.log("denialReason",this.denialReason);
    console.log("denialNote",this.denialNote);
    let input={
      jobId:this.jobDetails._id,
      denialReason:this.denialReason,
      denialPatientFacingNote:this.denialPatientFacingNote,
      denialNote:this.denialNote
    }
    if(!this.denialReason){
      this.toastr.error('Please select a reason for denial');
      return;
    }
    if(!this.denialPatientFacingNote){
      this.toastr.error('Please enter a patient facing note for denial');
      return;
    }

    if(!this.denialNote){
      this.toastr.error('Please enter a note for denial');
      return;
    }
    this.api.postData('ecommerce/deny-prescription',input).subscribe((res:any)=>{
      console.log("res denyPrescription",res);
    
      this.closeModal();
    },(err)=>{
      this.toastr.error('Error denying prescription');
    })
  }

  closeModal(){
    this.changeJobStatus('Denied');
    
  }
  changeJobStatus(status:any){
    let input:any={}
    input.webstoreTicketStatus=status
    this.api.putData(`webstore/job/changeStatus/${this.jobDetails._id}`,input).subscribe(res=>{
      console.log("res",res);
      this.storeDetails=res.data.storeId
      this.fullRefund();
      this.toastr.success('Prescription denied successfully');
      // this.bsModalRef.hide();
      // window.location.reload();
    })

  }

  fullRefund() {
    let input: any = {}
    input.chargeId = this.jobDetails.stripeInvoiceNumber;
    input.amount = (this.jobDetails.Soft_CL_Sales_Amount - this.stripeProcessingFee) + '';
    input.internalNotes = "full refund"
    input.reason = "duplicate"
    this.api.postData(`payment/refund/${this.api.user.enterpriseId.stripeConnectId}`, input).subscribe(
      (res) => {
        console.log("res", res);
        // this.toastr.success("Refund created successfully");
        this.denialOrCancelPaymentEmail()
            // this.bsModalRef.hide();
            // window.location.reload();
        // this.setCurrentItem( this.transactions[index],'charge')
      },
      (err) => {
        console.error("Error", err);
        this.api.showLoader = false;
      }
    );
  }

  denialOrCancelPaymentEmail(){
    let input:any={}
    input.jobId=this.jobDetails._id
    input.status='denied'
    input.denialPatientFacingNote=this.denialPatientFacingNote
    input.denialReason=this.denialReason
    input.storeDetails=this.storeDetails
    console.log("input denialOrCancelPaymentEmail",input);
    this.apiNotification.postData('notification/denialOrCancelPaymentEmail',input,true).subscribe(res=>{
      console.log("res",res);
      this.bsModalRef.hide();
      window.location.reload();
    })
  }

  closePopup(){
    this.bsModalRef.hide();
  }
}
