import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {

  user:any={};
  selectedJob:any={};
  jobData:any;
  search:any;
  filteredData:any;
  type = 'Eyeglass';
  prefix = 'Eyeglass_';

  Object = Object;

  constructor(
    private api:ApiService,
    private ds:DataService
  ) { }

  ngOnInit(): void {
    this.user = this.api.user;
    console.log('selectedJob', this.selectedJob);
    this.filteredData = JSON.parse(JSON.stringify(this.selectedJob));
    if(this.filteredData.Soft_CL_Order_Date){
      this.type = 'Soft Contact Lens';
      this.prefix = 'Soft_CL_';
    }
    if(this.filteredData.Hard_CL_Order_Date){
      this.type = 'Hard Contact Lens';
      this.prefix = 'Hard_CL_';
    }
    if(this.filteredData.businessType=='Online Webstore'){
      this.type = 'Soft Contact Lens';
      this.prefix = 'Soft_CL_';
    }
    this.selectedJob.shipType = this.selectedJob.Soft_CL_Ship_To == 'Patient' ? 'Direct to Patient (DTP)' : 'Direct to Office (DTO)';
    if(this.selectedJob?.updatedData?.paymentType == 'Cash'){
      this.selectedJob.Soft_CL_Insurance_Carrier = '';
      this.selectedJob.Soft_CL_Insurance_Allowance = 0;
      this.selectedJob.updatedData.insuranceAllowance = 0;
      this.selectedJob.updatedData.insuranceCopay = 0;
      this.selectedJob.updatedData.insuranceCarrier='';
    }
  }

  hideIt(key){
    //&& this.selectedJob[key]
    if(key.toLowerCase().match(new RegExp(this.search, "g")) ){
      return false;
    }else{
      return true;
    }
  }

  keyToName(key, prefix):string{
    return key.replace(prefix,'').replace(/_/g," ");
  }

  sum(a=0,b=0):number{
    return this.ds.sum(a,b);
  }
  
  lower(text){
    return text ? text.toLowerCase() : '';
  }

  LensRight = [
    "Eyeglass_Right_Type",
    "Eyeglass_Right_Material",
    "Eyeglass_Right_Style",
    "Eyeglass_Right_Color",
    "Eyeglass_Right_Coating",
    "Eyeglass_Right_Sphere",
    "Eyeglass_Right_Cylinder",
    "Eyeglass_Right_Axis",
    "Eyeglass_Right_Thickness",
    "Eyeglass_Right_Prism1",
    "Eyeglass_Right_Prism1_Direction",
    "Eyeglass_Right_Prism2",
    "Eyeglass_Right_Prism2_Direction",
    "Eyeglass_Right_Add1",
    "Eyeglass_Right_Add2",
    "Eyeglass_Right_Segment_Height",
    "Eyeglass_Right_OC_Height",
    "Eyeglass_Right_Far_PD",
    "Eyeglass_Right_Near_PD",
    "Eyeglass_Right_Base_Curve",
    "Eyeglass_Right_Slab_Off",
    "Eyeglass_Right_Balanced",    
  ]

  LensLeft = [
    "Eyeglass_Left_Type",
    "Eyeglass_Left_Material",
    "Eyeglass_Left_Style",
    "Eyeglass_Left_Color",
    "Eyeglass_Left_Coating",
    "Eyeglass_Left_Sphere",
    "Eyeglass_Left_Cylinder",
    "Eyeglass_Left_Axis",
    "Eyeglass_Left_Thickness",
    "Eyeglass_Left_Prism1",
    "Eyeglass_Left_Prism1_Direction",
    "Eyeglass_Left_Prism2",
    "Eyeglass_Left_Prism2_Direction",
    "Eyeglass_Left_Add1",
    "Eyeglass_Left_Add2",
    "Eyeglass_Left_Segment_Height",
    "Eyeglass_Left_OC_Height",
    "Eyeglass_Left_Far_PD",
    "Eyeglass_Left_Near_PD",
    "Eyeglass_Left_Base_Curve",
    "Eyeglass_Left_Slab_Off",
    "Eyeglass_Left_Balanced",  
  ];

  CL = [
    "Acuity_Type",
    "Consultant",
    "Dispensing_Notes",
    "Order_Date",
    "Order_Store_Number",
    "Original_Point_of_Sale_Number_For_Remake",
    "Parts_Instructions",
    "Parts_to_Supply_Left_Lens",
    "Parts_to_Supply_Right_Lens",
    "Point_of_Sale_Order_Number",
    "Ship_To",
    "Supply_Source",
  ];

  CLValues = [
    "Manufacturer",
    "Style",
    "Base",
    "Diameter",
    "Sphere",
    "Cylinder",
    "Axis",
    "Add",
    "Color",
    "Quantity",
  ]

}






var d = {
"Eyeglass_Acuity_Type": "",
"Eyeglass_Consultant": "",
"Eyeglass_Dispensing_Notes": "",
"Eyeglass_Extra_Apply": "",
"Eyeglass_Extra_Backside_A_R": "",
"Eyeglass_Extra_Coating_1": "",
"Eyeglass_Extra_Coating_2": "",
"Eyeglass_Extra_Coating_3": "",
"Eyeglass_Extra_Coating_4": "",
"Eyeglass_Extra_Edge": "",
"Eyeglass_Extra_Misc_1": "",
"Eyeglass_Extra_Misc_2": "",
"Eyeglass_Extra_Misc_3": "",
"Eyeglass_Extra_Misc_4": "",
"Eyeglass_Extra_Tint_Color": "",
"Eyeglass_Extra_Tint_Color_Comment": "",
"Eyeglass_Extra_Tint_Type": "",
"Eyeglass_Frame_A": "",
"Eyeglass_Frame_B": "",
"Eyeglass_Frame_Bridge": "",
"Eyeglass_Frame_Collection": "",
"Eyeglass_Frame_Color": "",
"Eyeglass_Frame_DBL": "",
"Eyeglass_Frame_DC_Lab_Number": "",
"Eyeglass_Frame_ED": "",
"Eyeglass_Frame_Edge_Type": "",
"Eyeglass_Frame_Eye": "",
"Eyeglass_Frame_Frame_Shape": "",
"Eyeglass_Frame_Frame_Status_Source": "",
"Eyeglass_Frame_Item_Number": "",
"Eyeglass_Frame_Name": "",
"Eyeglass_Frame_Safety_Frame": "",
"Eyeglass_Frame_Send_Frame_Trace": "",
"Eyeglass_Frame_Temple": "",
"Eyeglass_Frame_Type": "",
"Eyeglass_Frame_UPC": "",
"Eyeglass_Frame_Wrap": "",
"Eyeglass_Lab": "",
"Eyeglass_Lab_Instructions": "",
"Eyeglass_Lab_To_Make_Extras": "",
"Eyeglass_Lab_To_Make_Frame": "",
"Eyeglass_Lab_To_Make_Left_Lens": "",
"Eyeglass_Lab_To_Make_Right_Lens": "",
"Eyeglass_Left_Add1": "",
"Eyeglass_Left_Add2": "",
"Eyeglass_Left_Axis": "",
"Eyeglass_Left_Balanced": "",
"Eyeglass_Left_Base_Curve": "",
"Eyeglass_Left_Coating": "",
"Eyeglass_Left_Color": "",
"Eyeglass_Left_Cylinder": "",
"Eyeglass_Left_Far_PD": "",
"Eyeglass_Left_Material": "",
"Eyeglass_Left_Near_PD": "",
"Eyeglass_Left_OC_Height": "",
"Eyeglass_Left_Prism1": "",
"Eyeglass_Left_Prism1_Direction": "",
"Eyeglass_Left_Prism2": "",
"Eyeglass_Left_Prism2_Direction": "",
"Eyeglass_Left_Segment_Height": "",
"Eyeglass_Left_Slab_Off": "",
"Eyeglass_Left_Sphere": "",
"Eyeglass_Left_Style": "",
"Eyeglass_Left_Thickness": "",
"Eyeglass_Left_Type": "",
"Eyeglass_Near_Only": "",
"Eyeglass_Order_Date": "",
"Eyeglass_Order_Store_Number": "",
"Eyeglass_Order_Type": "",
"Eyeglass_Original_Point_of_Sale_Number_For_Remake": "",
"Eyeglass_Pantoscopic_Tilt": "",
"Eyeglass_Point_of_Sale_Order_Number": "",
"Eyeglass_Remake_Reason": "",
"Eyeglass_Right_Add1": "",
"Eyeglass_Right_Add2": "",
"Eyeglass_Right_Axis": "",
"Eyeglass_Right_Balanced": "",
"Eyeglass_Right_Base_Curve": "",
"Eyeglass_Right_Coating": "",
"Eyeglass_Right_Color": "",
"Eyeglass_Right_Cylinder": "",
"Eyeglass_Right_Far_PD": "",
"Eyeglass_Right_Material": "",
"Eyeglass_Right_Near_PD": "",
"Eyeglass_Right_OC_Height": "",
"Eyeglass_Right_Prism1": "",
"Eyeglass_Right_Prism1_Direction": "",
"Eyeglass_Right_Prism2": "",
"Eyeglass_Right_Prism2_Direction": "",
"Eyeglass_Right_Segment_Height": "",
"Eyeglass_Right_Slab_Off": "",
"Eyeglass_Right_Sphere": "",
"Eyeglass_Right_Style": "",
"Eyeglass_Right_Thickness": "",
"Eyeglass_Right_Type": "",
"Eyeglass_Ship_To": "",
"Eyeglass_Uncut": "",
"Eyeglass_Vertex": "",
"Hard_CL_Acuity_Type": "",
"Hard_CL_Consultant": "",
"Hard_CL_Dispensing_Notes": "",
"Hard_CL_Lens_Left_Add": "",
"Hard_CL_Lens_Left_Axis": "",
"Hard_CL_Lens_Left_Base": "",
"Hard_CL_Lens_Left_Color": "",
"Hard_CL_Lens_Left_Cylinder": "",
"Hard_CL_Lens_Left_Diameter": "",
"Hard_CL_Lens_Left_Manufacturer": "",
"Hard_CL_Lens_Left_Quantity": "",
"Hard_CL_Lens_Left_Sphere": "",
"Hard_CL_Lens_Left_Style": "",
"Hard_CL_Lens_Right_Add": "",
"Hard_CL_Lens_Right_Axis": "",
"Hard_CL_Lens_Right_Base": "",
"Hard_CL_Lens_Right_Color": "",
"Hard_CL_Lens_Right_Cylinder": "",
"Hard_CL_Lens_Right_Diameter": "",
"Hard_CL_Lens_Right_Manufacturer": "",
"Hard_CL_Lens_Right_Quantity": "",
"Hard_CL_Lens_Right_Sphere": "",
"Hard_CL_Lens_Right_Style": "",
"Hard_CL_Order_Date": "",
"Hard_CL_Order_Store_Number": "",
"Hard_CL_Original_Point_of_Sale_Number_For_Remake": "",
"Hard_CL_Parts_Instructions": "",
"Hard_CL_Parts_to_Supply_Left_Lens": "",
"Hard_CL_Parts_to_Supply_Right_Lens": "",
"Hard_CL_Point_of_Sale_Order_Number": "",
"Hard_CL_Ship_To": "",
"Hard_CL_Supply_Source": "",
"Patient_Point_of_Sale_Patient_ID": "4625923",
"Soft_CL_Acuity_Type": "Soft CL",
"Soft_CL_Consultant": "CHRISTAL MEREDITH",
"Soft_CL_Dispensing_Notes": "",
"Soft_CL_Instructions": "",
"Soft_CL_Lens_Left_Add": "",
"Soft_CL_Lens_Left_Axis": "170",
"Soft_CL_Lens_Left_Base": "8.6",
"Soft_CL_Lens_Left_Color": "Clear",
"Soft_CL_Lens_Left_Cylinder": "-1.25",
"Soft_CL_Lens_Left_Diameter": "14.5",
"Soft_CL_Lens_Left_Manufacturer": "Cooper Vision",
"Soft_CL_Lens_Left_Quantity": "1",
"Soft_CL_Lens_Left_Sphere": "-6",
"Soft_CL_Lens_Left_Style": "Myday Toric 90pk",
"Soft_CL_Lens_Right_Add": "",
"Soft_CL_Lens_Right_Axis": "10",
"Soft_CL_Lens_Right_Base": "8.6",
"Soft_CL_Lens_Right_Color": "Clear",
"Soft_CL_Lens_Right_Cylinder": "-0.75",
"Soft_CL_Lens_Right_Diameter": "14.5",
"Soft_CL_Lens_Right_Manufacturer": "Cooper Vision",
"Soft_CL_Lens_Right_Quantity": "1",
"Soft_CL_Lens_Right_Sphere": "-6.5",
"Soft_CL_Lens_Right_Style": "Myday Toric 90pk",
"Soft_CL_Order_Date": "10/25/22",
"Soft_CL_Order_Store_Number": "8748",
"Soft_CL_Original_Point_of_Sale_Number_For_Remake": "",
"Soft_CL_Parts_to_Supply_Left_Lens": "checked",
"Soft_CL_Parts_to_Supply_Right_Lens": "checked",
"Soft_CL_Point_of_Sale_Order_Number": "10153028",
"Soft_CL_Ship_To": "This Office",
"Soft_CL_Supply_Source": "Inventory",
"chatStatus": "INACTIVE",
"createdAt": "2022-11-10T14:23:22.885Z"
}

