import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-note-view',
  templateUrl: './note-view.component.html',
  styleUrls: ['./note-view.component.css']
})
export class NoteViewComponent implements OnInit {
  public onClose: Subject<any>;
  senderMessages: any[] = [];
  orderChats: any[] = [];
  newMessage: string = '';
  search: string = ''; 
  chatData: any = { orderChats: [], orderDetail: {} };
  jobId: string = '';
  url: string = "";
  file: any;
  template: string = "NOTES";
  selectedJob:any={};
  officeAssistance:any={
    attachment: []
  };
  scheduleDate = new Date();
  refreshDisabled: boolean = false;
  item:any={};
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(
    private api:ApiService,
    private ts: ToastrService ,
    private bsModalRef: BsModalRef,
    private ds:DataService
  ) { }

  ngOnInit(): void {
    console.log("item notes",this.item);
    if (this.item._id) {
      this.getOrderChats(this.item._id);
    }
      this.getJobDetails();
   
  }
  
 getOrderChats(jobId: string): void {
  let input: any = {};
  input.skip = 0;
  input.limit = 100;
  this.search ? input.search = this.search : delete input.search;
  this.api.showLoader = false;
  this.api.getData(`jobNotes/${jobId}`, input).subscribe((response: any) => {
    console.log("getOrderChats",response);
   if(response){
   this.chatData = {
    orderChats : response?.listing,
    orderDetail : response?.data?.orderDetails
     }
     this.scrollToBottom();
   } 
    },
    error => {
      console.error('Error fetching messages:', error);
    }
  );
}

closeModal() {
  this.bsModalRef.hide(); 
  this.onClose.next('Modal Closed');
}

handleKeydown(event: KeyboardEvent): void {
  if (event.key === 'Enter') {
    if (event.shiftKey) {
      return;
    }
    event.preventDefault();
    this.sendMessage();
  }
}

switchTemplate(view: string): void {
  this.template = view;
  if (view === 'NOTES') {
    this.scrollToBottom();
  } 
}

scrollToBottom(): void {
  setTimeout(()=>{
    try {
      // console.log('scroll',this.myScrollContainer.nativeElement.scrollTop);
      // this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      this.myScrollContainer.nativeElement.scrollTo({ top: this.myScrollContainer.nativeElement.scrollHeight, behavior: 'smooth' })
    } catch(err) { }  
  },500)
             
}


sendMessage(): void {
  if (!this.newMessage.trim() && !this.file) {
    this.ts.error('Please enter a message or attach a file!');
    return;
  }

  const input: any = {
    orderId: this.item._id,
  };
  if(this.newMessage){
    input.messages = this.newMessage;
  }
  if (this.file) {
    this.uploadImage(this.file).then((uploadResponse) => {
      input.attachment = uploadResponse?.original;
      this.submitMessage(input);
    }).catch((error) => {
      console.error('Image upload failed:', error);
    });
  } else {
    this.submitMessage(input);
  }
} 
submitMessage(input: any): void {
  this.api.postData('jobNotes', input).subscribe(
    (response) => {
      this.chatData.orderChats.push(response);
      this.newMessage = '';
      this.file = null;
      this.getOrderChats(this.item._id);
      this.scrollToBottom();
    },
    (error) => {
      console.error('Error sending message:', error);
    }
  );
}


onSelectFile(event: Event): void {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files[0]) {
    this.file = target.files[0];
    const input: any = {
      orderId: this.item._id,
      messages: null,  
    };

    this.uploadImage(this.file).then((uploadResponse) => {
      input.attachment = uploadResponse?.original;
      this.submitMessage(input);  
    }).catch((error) => {
      console.error('File upload failed:', error);
    });
  }
}

async onFileSelect(event){
  if(event.target.files && event.target.files[0]){
    const file = event.target.files[0];
    console.log(file);
    const formData = new FormData();
    formData.append('image',file);
    this.api.postData('common/uploadImage',formData).subscribe(res=>{
      this.officeAssistance.attachment.push(res.original);
    },error=>{   
    });
  }
}

  uploadImage(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", file);
      this.api.postData("common/uploadImage", formData).subscribe(
        (res) => {
          console.log('Upload Response:', res); 
          if (res && res.original) {
            resolve(res); 
          } else {
            reject('Image upload failed');
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  } 

  getJobDetails(){
    this.api.getData(`jobs/${this.item._id}`,{}).subscribe(res=>{
      this.selectedJob = res['data'];
      console.log(this.selectedJob);
      // this.selectedJob.shipType = this.selectedJob.Soft_CL_Ship_To == 'Patient' ? 'Direct to Patient (DTP)' : 'Direct to Office (DTO)';
      // if(this.selectedJob.OfficeAssistanceId){
      //   this.officeAssistance = this.selectedJob.OfficeAssistanceId;
      //   this.scheduleDate = new Date(this.officeAssistance.dateAndTime);
        
      // }
      // if(this.selectedJob.OfficeAssistanceId){
      //   this.template = 'TICKET';
      // }

      // this.isEmailResendAllowed();
    });
  }

  // isEmailResendAllowed() {
  //   const currentTime = new Date().getTime();
  //   if (this.officeAssistance?.reminderEmailHistory?.length) {
  //     const lastReminder = this.officeAssistance.reminderEmailHistory[this.officeAssistance.reminderEmailHistory.length - 1];
  //     const lastReminderTime = new Date(lastReminder.emailDateAndTime).getTime();
  //     if (lastReminderTime > currentTime) {
  //       console.log('Future reminder scheduled:', lastReminder.emailDateAndTime);
  //       this.refreshDisabled = true;
  //     } else {
  //       this.refreshDisabled = false;
  //     }
  //   } else {
  //     this.refreshDisabled = false;
  //   }
  // }
  
  shouldShowInitialClock(): boolean {
    if (this.officeAssistance?.isSchedule) {
      return this.isFutureDate(this.officeAssistance?.dateAndTime);
    }
    return false;
  }

  isFutureDate(dateString: string): boolean {
    const currentTime = new Date().getTime();
    const checkTime = new Date(dateString).getTime();
    return checkTime > currentTime;
  }
   resendEmail() {
    // if (this.refreshDisabled) {
    // return;
    // }
    console.log('Resending email...');
    this.api.getData(`god/reSendSupportEmail/${this.jobId}`, {}).subscribe(
      (res) => {
        this.getJobDetails();
         this.refreshDisabled = true;
         this.getJobDetails();
      },
      (err) => {
        this.refreshDisabled = false;
      }
    );
  }  

  removeAttachement(index){
    this.officeAssistance.attachment.splice(index,1);
  }


  update(){
    let input:any={}
    if (this.officeAssistance.notes) {
      input.notes = this.officeAssistance.notes;
    }
    if (this.officeAssistance.attachment) {
      input.attachment = this.officeAssistance.attachment;
    }
  
    // if (this.officeAssistance.isSchedule !== undefined) {
    //   input.isSchedule = this.officeAssistance.isSchedule ? true : false;
    //   input.dateAndTime = this.scheduleDate || null;
    // }
    this.api.putData(`god/jobSupport/${this.officeAssistance._id}`,input).subscribe(res=>{
      console.log("ressss", res);
      this.onClose.next('OfficeAssistance');
      this.bsModalRef.hide();
      this.ts.success(res.message);
    },error=>{
      this.ts.error(error.error.message);
    });
  }

  claimStatusChange(item){
    let input:any={};
    input.claimStatus = true;
    input.claimStatusPersonName = 'SUPPORT';
    input.claimStatusBy = 'SUPPORT';
    this.api.putData(`god/jobSupport/${item._id}`,input).subscribe(r=>{
      item.claimStatusPersonName = input.claimStatusPersonName
    },error=>{
      item.claimStatus = false;
    });
  }

  openModalViewImage(url){
    this.ds.openModalViewImage({url:url})
  }

}
