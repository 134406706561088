<div class="prescription-modal" id="expiryModal22" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-header justify-content-center">
    <h3 class="modal-title">Prescription Upload</h3>
  </div>
  <div class="modal-body p-4">
    <div class="payment-success-content">
      <div class="customer-content">
        <p class="text-center">We've found your patient profile, <span
            style="text-transform: capitalize;">{{patientDetails?.patientId?.Patient_First_Name}}</span>, but we need you to upload your
          prescription so we can validate it's not expired and the prescribed lens. Please click below to do so.</p>
        <div class="row" *ngIf="prescriptionUrl">
          <div class="col-md-6 mx-auto">
            <span class="link-flag w-100 text-center">
              <img class="remove-doc" src="assets/images/close.png" (click)="onRemovePrescription()" />
              <span style="cursor: pointer;"><img src="assets/images/link-flag.svg" /><a href={{prescriptionUrl}}
                  target="_blank">{{ePrescriptionName}}</a></span></span>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-block mx-auto" *ngIf="!prescriptionUrl">
          <!-- <label class="mb-2">Open Prescription</label> -->
          <div class="form-group file-upload-group">
            <input type="file" (change)="onAttachFile($event)" />
            <div class="file-wrap">
              <div class="file-upload-wrap py-2 px-3 text-center">
                <img class="me-3" src="assets/images/paper-clip.svg" />
                <span>Upload File</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <a  class="btn-primary btn mx-2 mt-4" href="mailto:{{currentCustomer?.storesInfo?.email}}">Email</a> -->
        <!-- {{currentCustomer.storeInfo|json}} -->
        <div class="error-wrap py-3 my-1 text-center">
          <div *ngIf="errorMsg" class="danger-alert py-2 px-4 d-inline-block text-center">{{errorMsg}}</div>
        </div>
        <div class="d-flex justify-content-center align-items-center" (click)="onUploadPrescription()">
          <a class="btn-primary btn" style="min-width: 240px;">Proceed</a>
        </div>
      </div>
    </div>
  </div>
</div>