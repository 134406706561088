<div class="prescription-modal" id="expiryModal22" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-header justify-content-center">
      <h3 class="modal-title">Prescription Upload</h3>
    </div>
    <div class="modal-body p-4">
      <div class="payment-success-content">
        <div class="customer-content">
          <p class="text-center">We've found your patient profile, <span
              style="text-transform: capitalize;"></span>, but we need you to upload your
            prescription so we can validate it's not expired and the prescribed lens. Please click below to do so.</p>
          <!-- <a  class="btn-primary btn mx-2 mt-4" href="mailto:{{currentCustomer?.storesInfo?.email}}">Email</a> -->
          <!-- {{currentCustomer.storeInfo|json}} -->
          <div class="d-flex justify-content-center align-items-center">
            <a class="btn-primary btn" style="min-width: 240px;">Proceed</a>
          </div>
        </div>
      </div>
    </div>
  </div>